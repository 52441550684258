import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, Col, Form, Input, Row } from "reactstrap";
import BackImg from "../../assets/settings-265131_1920_2021-11-02/settings-265131_1920.png";

import "./profile.scss";
const MyHospital = () => {
  const [longitude, setLongitude] = useState("");

  const handlelongitude = (e) => {
    setLongitude(e.target.value);
  };
  const OnSubmitHandler = (e) => {
    e.preventDefault();
  };
  return (
    <React.Fragment>
      <img src={BackImg} alt="backimg" className="dashboardBackImg" />
      <div className="account-pages">
        <Row className="dashRow m-0">
          <Col md="5">
            <Form onSubmit={OnSubmitHandler}>
              <Card className="listAddCard">
                <div className="myHosWrap">
                  <div className="d-flex justify-content-center">
                    <span className="myHosName">병원에 연결하기</span>
                  </div>
                  <div className="myHosTitle">
                    의료 전문가에게 혈당 데이터에 대한 계속적인 엑세스 권한을
                    부여하려면 해당 병원 ID를 여기에 입력하십시오.
                  </div>
                  <div className="myHosSubTitle">
                    병원 ID는 LibreView 시스템에서 각 병원에 고유한 8-16자의
                    코드입니다. 귀하가 계정을 연결할 수 있도록 담당 의료
                    전문가에게 해당 LibreView 병원 ID를 달라고 하십시오.
                  </div>
                  <div className="myHosInputRow">
                    <label htmlFor="_longitude" className="profileInCardName">
                      병원 ID 입력
                    </label>
                    <Input
                      type="text"
                      name="_longitude"
                      className="profileInCardInput"
                      value={longitude}
                      onChange={handlelongitude}
                      placeholder="ㅇㅇㅇ"
                    />
                  </div>
                </div>
              </Card>
              <div className="profileBtn">
                <button className="profileBtn326" type="submit">
                  <span>추가</span>
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default withRouter(MyHospital);
