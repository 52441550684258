import React from "react";
import ReactApexChart from "react-apexcharts";

const ApexCharts = ({ data }) => {
  const series = [
    {
      name: "series1",
      data: data,
    },
  ];

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    yaxis: {
      show: true,
      min: 0,
      max: 360,
      tickAmount: 4,
    },
    annotations: {
      yaxis: [
        // {
        //   y: 30,
        //   strokeDashArray: 0,
        //   borderColor: "#00E396",
        //   label: {
        //     borderColor: "#00E396",
        //     style: {
        //       color: "#fff",
        //       background: "#00E396",
        //     },
        //     text: "test",
        //   },
        // },
        {
          y: 0,
          y2: 70,
          borderColor: "#000",
          fillColor: "#FFFABE",
          opacity: 0.2,
        },
        {
          y: 70,
          y2: 180,
          borderColor: "#000",
          fillColor: "#898989",
          opacity: 0.2,
        },
      ],
    },

    colors: ["#70B6C1"],
    xaxis: {
      show: false,
      categories: ["00:00", "12:00", "00:00"],
    },
    chart: {
      // offsetY: 200,
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      // height="350"
      className="apex-charts"
      dir="ltr"
      width="100%"
      height="100%"
    />
  );
};

export default ApexCharts;
