import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass";
import "./main.scss";
import { agmsClientRequestGet, requestGet, agmsClientRequestPost, } from "../../apis/request";
import Loading from "../../assets/uxnicon/loadingIcon.png";
import moment from "moment";
import { useRecoilState, useSetRecoilState, atom } from "recoil";
import { userID } from "../../states/recoilState";
import { Label } from "reactstrap";
import secureLocalStorage from "react-secure-storage";
import Button from '@mui/material/Button';
import { badgeOnOffAtom } from "../../states/recoilState";

const AcceptPatientList = () => {
  const [badgeOnOffState, setBadgeOnOffState] = useRecoilState(badgeOnOffAtom);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM"));
  const setuserID = useSetRecoilState(userID);
  const history = useHistory();
  
  const callApi = async () => {
    // setLoading(true);
    // let idxObj = JSON.parse(secureLocalStorage.decoded);
    // try {
    //   let res = await requestGet(
    //     `/api/v1/user/staff-info/un-recognize/list/${idxObj.idx}`
    //   );
    //   setData(res);
    // } catch (error) {
    //   console.log(error);
    // } finally {
    //   setLoading(false);
    // }
    try{
      console.log('여기는 AcceptPatient');
      let listResult = await agmsClientRequestGet(`/api/staff/approval/list/${secureLocalStorage.getItem('hospitalId')}`);
      setData(listResult);
      console.log(listResult);
    }catch(error){

    }
  };

  const callApproveApi = async(staff_id) => {
    console.log('여기는 callApproveApi');
    let approveResult = await agmsClientRequestPost(`/api/staff/approval/${staff_id}`);
    // if(approveResult.message.conta){

    // }
    console.log(approveResult.message);
    setBadgeOnOffState(staff_id);

    alert('승인 되었습니다!');
    callApi();
  };

  const callSearchApi = async () => {
    setLoading(true);
    // const params = new URLSearchParams();
    // params.append("userName", name);
    try {
      let res = await requestGet(`/api/v1/staff/search/${name}`);
      setData([res]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setData([
      {
        name: 'abc',
        birth: 'asdf',
        email:'asdf'
      }
    ]);
    callApi();
  }, []);



  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (name.length > 0) {
        callSearchApi();
      } else {
        callApi();
      }
    }
  };

  const onStartChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleApproveClick = (input) => {
    callApproveApi(input.id);
    console.log('input');

    console.log(input);
  }
  /*
  get(`/api1/v1/user/approval/list`)
  { id: data.id
    name : data.name,
    age : data.age,
    date : data.date,
    gender : data.gender,
    addres : data.addres,
    email : data.email,
    phonenumber : data.phonenumber
  }

  get(`/api/v1/login/attempt`)
  statuscode
  200~299 = 정상
  411 = 로그인 시도 있음
*/
  return (
    <div
      className="row w-100 h-100 d-flex align-items-center"
      style={{ padding: "0px 70px", marginTop: "80px",display:'flex', justifyContent:'center'}}
    >
      {!loading && data !== null ? (
        <div style={{ maxWidth:'1500px'}}>
          <div
            style={{
              marginBottom: "17px",
              display: "flex",
              justifyContent: "space-between",
             
            }}
          >
            <div
              className="d-flex align-items-end"
              style={{ visibility: "hidden"}}
            >
              <div
                className="input-group"
                style={{ width: "320px", marginRight: "10px"}}
              >
                <span
                  className="input-group-text"
                  id="basic-addon1"
                  style={{ backgroundColor: "#fff"}}
                >
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    color="#84818A"
                    onClick={() => callSearchApi()}
                    style={{ cursor: "pointer" }}
                  />
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="환자 검색"
                  style={{
                    borderLeft: "none",
                    padding: "0.375rem 0.75rem 0.375rem 0px",
                  }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
              </div>
              <div>
                <Label for="example-date-input" className="form-label">
                  기간선택
                </Label>
                <input
                  className="form-control"
                  type="month"
                  value={startDate}
                  onChange={onStartChange}
                  max={moment().format("YYYY-MM")}
                  id="example-date-input"
                  style={{
                    width: "257px",
                    marginRight: "10px",
                    borderRadius: "10px",
                    border: "2px solid #CBCBCB",
                  }}
                />
              </div>
            </div>
            
          </div>
          <div>
            <table style={{ minWidth: "100%" }}>
              <thead>
                <tr
                  style={{
                    color: "#fff",
                    fontWeight: "bold",
                    fontFamily: "Noto Sans CJK KR",
                     
                  }}
                >
                  <th
                    style={{
                      backgroundColor: "#787397",
                      borderRadius: "5px 0px 0px 5px",
                      padding: "10px 20px",
                      borderRight: "1px solid #EBEBEB",
                      textAlign: "center",
                      minWidth: "70px",
                    }}
                  >
                    이름
                  </th>
                  <th
                    style={{
                      backgroundColor: "#787397",
                      padding: "10px 20px",
                      borderRight: "1px solid #EBEBEB",
                      textAlign: "center",
                      minWidth: "100px",
                    }}
                  >
                    의사번호
                  </th>
                  <th
                    style={{
                      backgroundColor: "#787397",
                      padding: "10px 20px",
                      borderRight: "1px solid #EBEBEB",
                      textAlign: "center",
                      minWidth: "250px",
                    }}
                  >
                    이메일
                  </th>
                  <th
                    style={{
                      backgroundColor: "#787397",
                      borderRadius: "0px 5px 5px 0px",
                      padding: "10px 20px",
                      textAlign: "center",
                      minWidth: "140px",
                    }}
                  >
                    승인여부
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, i) => {
                  return (
                    <tr key={i} >
                      <td
                        style={{
                          backgroundColor: "#fff",
                          textAlign: "center",
                          borderRight: "1px solid #EBEBEB",
                          borderBottom: "1px solid #EBEBEB",
                        }}
                      >
                        {item.name}
                      </td>
                      <td
                        style={{
                          backgroundColor: "#fff",
                          textAlign: "center",
                          borderRight: "1px solid #EBEBEB",
                          borderBottom: "1px solid #EBEBEB",
                        }}
                      >
                        {item.id}
                      </td>
                      <td
                        style={{
                          backgroundColor: "#fff",
                          textAlign: "center",
                          borderRight: "1px solid #EBEBEB",
                          borderBottom: "1px solid #EBEBEB",
                        }}
                      >
                        {item.email}
                      </td>
                      <td
                        style={{
                          backgroundColor: "#fff",
                          textAlign: "center",
                          borderBottom: "1px solid #EBEBEB",
                        }}
                      >
                        <div style={{marginTop:'10px', marginBottom:'10px'}}>
                          <Button variant="contained" onClick={()=>handleApproveClick(item)} style={{backgroundColor:'#E4DBEF', color:'#000000', fontWeight:'bold'}}>승인</Button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div
          className="divsWrap"
          style={{
            padding: "36px 147px",
            position: "absolute",
            left: 0,
            top: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 10001,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={Loading}
              alt="loading..."
              style={{
                width: "50px",
                height: "50px",
                animation: ".75s linear infinite spinner-border",
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(AcceptPatientList);
