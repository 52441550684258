import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, Col, Form, Input, Row } from "reactstrap";
import BackImg from "../../assets/settings-265131_1920_2021-11-02/settings-265131_1920.png";
import "./profile.scss";
import ProfileDropdown from "./ProfileDropdown";
const Profile = () => {
  const [editOpen, setEditOpen] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const handlelatitude = (e) => {
    setLatitude(e.target.value);
  };

  const handlelongitude = (e) => {
    setLongitude(e.target.value);
  };
  const OnSubmitHandler = (e) => {
    e.preventDefault();
    setEditOpen(false);
  };
  return (
    <React.Fragment>
      <img src={BackImg} alt="backimg" className="dashboardBackImg" />
      <div className="account-pages">
        <Row className="dashRow m-0">
          <Col md="5">
            <Form onSubmit={OnSubmitHandler}>
              <Card className="listAddCard">
                <div className="threeDotsWrap">
                  <ProfileDropdown setEditOpen={setEditOpen} />
                </div>
                <div className="profileInCard">
                  <div className="d-flex justify-content-center">
                    <span className="profileName">Karis Kim</span>
                  </div>
                  <div className="birthWrap">
                    <span className="birthTitle">생년월일</span>
                  </div>
                  <div className="birthDayWrap">
                    <span className="birthDay">1978.02.26</span>
                  </div>
                  <form>
                    <div className="profileInCardRow">
                      <label htmlFor="_latitude" className="profileInCardName">
                        이메일
                      </label>
                      <Input
                        type="text"
                        name="_latitude"
                        className="profileInCardEmailInput"
                        value={latitude}
                        onChange={handlelatitude}
                        placeholder="이메일"
                        disabled={!editOpen}
                      />
                    </div>

                    <div className="profileInCardRow">
                      <label htmlFor="_longitude" className="profileInCardName">
                        비밀번호
                      </label>
                      <Input
                        type="text"
                        name="_longitude"
                        className="profileInCardInput"
                        value={longitude}
                        onChange={handlelongitude}
                        placeholder="비밀번호"
                        disabled={!editOpen}
                      />
                    </div>
                  </form>
                </div>
              </Card>
              {editOpen ? (
                <div className="profileBtn">
                  <button className="profileBtn326" type="submit">
                    <span>저장</span>
                  </button>
                </div>
              ) : (
                ""
              )}
            </Form>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default withRouter(Profile);
