import React, { useCallback, useEffect, useState } from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import TitleImg from "../../assets/uxnicon/8.png";
import "./login.scss";
import {
  loginPost,
  requestGet,
  requestPatch,
  requestPut,
  agmsClientRequestPatch,
  agmsClientLoginPost
} from "../../apis/request";
import jwt_decode from "jwt-decode";
import NoPw from "../../assets/uxnicon/nopw.png";
import OkPw from "../../assets/uxnicon/okpw.png";
import secureLocalStorage from "react-secure-storage";


const ChangePW = (props) => {
  const [name, setName] = useState("");
  const [loginId, setLoginId] = useState("");
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [isPassword, setIsPassword] = useState(false);
  const [isPasswordSignUp, setIsPasswordSignUp] = useState(false);
  const [gender, setGender] = useState("MALE");
  const [logInUp, setLogInUp] = useState(false);
  const history = useHistory();
  const params = useParams();

  const clickSignUp = async (e) => {
    if (password === passwordCheck) {
      if (password !== "") {
        try {
          e.preventDefault();
          let body = {
            password: password,
            code: params.code,
          };
          console.log(params);
          console.log(params.email);
          console.log(params.code);

          //로그인 전
          let res = await agmsClientRequestPatch(
            `/api/staff/password-initialize/${params.email}`, body
          );

          // let res = await agmsClientRequestPatch(
          //   `/api/staff/password-update/${params.email}`, body
          // );

          if (res.status !== 500) {
            // let body = {
            //   //user_id: params.email,
            //   password: password,
            // };
            // try {
            //   let res1 = await agmsClientLoginPost(`/api/login`, body);
            //   let token = res1.split(" ");
            //   let authToken = token[1];
            //   let decoded = jwt_decode(authToken);
            //   let refreshtoken = token[3];
            //   secureLocalStorage.setItem("authUser", authToken);
            //   secureLocalStorage.setItem("refreshtoken", refreshtoken);
            //   secureLocalStorage.setItem("decoded", JSON.stringify(decoded));
            //   history.push("/");
            // } catch (error) {
            //   if (error.response?.status === 416) {
            //     alert("비밀번호 찾기를 시도해주세요.");
            //   } else if (error.response?.status === 412) {
            //     alert(
            //       "메일 인증시간이 초과 되었습니다. 다시 회원가입을 해주세요."
            //     );
            //   } else if (error.response?.status === 410) {
            //     alert("회원 인증 기간이 만료되었습니다.");
            //   } else if (error.response?.status === 401) {
            //     alert("로그인 정보를 확인해주세요.");
            //   } else {
            //     alert("로그인을 할 수 없습니다.");
            //   }
            // }
            alert('성공적으로 변경되었습니다.');
            history.push('/login');
          }
        } catch (error) {
          if (error.response?.status === 404) {
            alert("사용자를 찾을 수 없습니다.");
          }
        }
      } else {
        alert("정보를 기입해 주세요.");
      }
    } else {
      alert("비밀번호확인이 안맞습니다.");
    }
  };

  const onChangePasswordSignUp = (e) => {
    const passwordSignUpRegex =
      /^(?=.*[a-zA-Z])(?=.*[!@%$%^&*()+=._-])(?=.*[0-9]).{8,35}$/;
    const passwordSignUpCurrent = e.target.value;
    setPassword(passwordSignUpCurrent);
    if (!passwordSignUpRegex.test(passwordSignUpCurrent)) {
      setIsPasswordSignUp(false);
    } else {
      setIsPasswordSignUp(true);
    }
    if (passwordSignUpCurrent.length === 0) {
      setIsPasswordSignUp(false);
    }
  };

  return (
    <React.Fragment>
      <div
        className="row m-0 p-0"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#0000009A",
          minHeight: "100vh",
        }}
      >
        <div
          className="col-12 col-lg-8 d-flex flex-column align-items-center justify-content-center m-0"
          style={{
            backgroundColor: "#fff",
            padding: "114px 0px",
          }}
        >
          <div style={{ margin: "0px 0px 41px 0px" }}>
            <img src={TitleImg} alt="title" style={{ height: "52px" }} />
          </div>
          <div
            className="row m-0 p-0 justify-content-center"
            style={{
              width: "100%",
              marginBottom: "20px",
            }}
          >
            <div
              className="col-8 m-0 p-0"
              style={{ fontSize: "24px", fontWeight: "600" }}
            >
              비밀번호 재설정
            </div>
          </div>
          <div
            className="row m-0 p-0 justify-content-center"
            style={{ width: "100%", marginBottom: "20px" }}
          >
            <div
              className="col-8 p-0"
              style={{
                fontSize: "15px",
                fontWeight: "300",
                color: "#000000",
                margin: "0px 0px 24px 0px",
              }}
            >
              새 비밀번호를 입력하고 계속을 누릅니다.
            </div>
          </div>
          <div
            className="row m-0 p-0 justify-content-center"
            style={{ width: "100%", marginBottom: "20px" }}
          >
            <div
              className="col-8 p-0"
              style={{
                fontSize: "18px",
                fontWeight: "300",
                color: "#000000",
                margin: "0px 0px 19px 0px",
              }}
            >
              새 비밀번호
            </div>
          </div>
          <div
            className="row m-0 p-0 justify-content-center"
            style={{ width: "100%", marginBottom: "20px" }}
          >
            <div className="col-8 m-0 p-0">
              <div
                className="input-group"
                style={{ width: "100%", marginBottom: "18px" }}
              >
                <input
                  type="password"
                  className="loginInputPw"
                  style={{
                    borderRadius: "6px 0px 0px 6px",
                    borderRight: "none",
                    padding: "13px 16px",
                    color: "#56C79C",
                  }}
                  placeholder="PW"
                  value={password}
                  onChange={onChangePasswordSignUp}
                />
                {isPasswordSignUp ? (
                  <span
                    className="loginInputPwspan"
                    id="basic-addon1"
                    style={{
                      width: "111px",
                      backgroundColor: "#fff",
                      display: "flex",
                      alignItems: "center",
                      borderLeft: "none",
                    }}
                  >
                    사용가능
                    <img
                      src={OkPw}
                      alt="wrongPw"
                      style={{
                        width: "12px",
                        height: "15px",
                        marginLeft: "7px",
                      }}
                    />
                  </span>
                ) : (
                  <span
                    className="loginInputPwspan"
                    id="basic-addon1"
                    style={{
                      width: "111px",
                      backgroundColor: "#fff",
                      display: "flex",
                      alignItems: "center",
                      borderLeft: "none",
                      color: "#FF8EAD",
                    }}
                  >
                    사용불가
                    <img
                      src={NoPw}
                      alt="wrongPw"
                      style={{
                        width: "12px",
                        height: "15px",
                        marginLeft: "7px",
                      }}
                    />
                  </span>
                )}
              </div>
            </div>
            <div
              className="row m-0 p-0 justify-content-center"
              style={{ width: "100%", marginBottom: "20px" }}
            >
              <div className="col-8 m-0 p-0">
                <input
                  type="password"
                  className={
                    password !== passwordCheck && passwordCheck !== ""
                      ? "loginInput"
                      : "loginInput loginEmailInput_cpw"
                  }
                  style={{
                    width: "100%",
                    borderRadius: "6px",
                    padding: "13px 16px",
                    color: "#56C79C",
                  }}
                  placeholder="Check PW"
                  value={passwordCheck}
                  onChange={(e) => setPasswordCheck(e.target.value)}
                />
                {password !== passwordCheck && passwordCheck !== "" ? (
                  <div
                    style={{
                      width: "100%",
                      padding: "5.5px 0px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        lineHeight: 1,
                        color: "#FF8EAD",
                      }}
                    >
                      비밀번호가 일치하지 않습니다.
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className="row m-0 p-0 justify-content-center"
              style={{ width: "100%", marginBottom: "20px" }}
            >
              <div className="col-8 m-0 p-0">
                <div
                  className="row m-0 p-0 justify-content-center"
                  style={{ width: "100%", marginBottom: "20px" }}
                >
                  <div className="col m-0 p-0">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "16px",
                          color: "#000000",
                        }}
                      >
                        비밀번호 요구사항
                      </div>
                      <div
                        style={{
                          fontSize: "16px",
                          color: "#000000",
                          fontWeight: "300",
                        }}
                      >
                        · 8~36자
                      </div>
                      <div
                        style={{
                          fontSize: "16px",
                          color: "#000000",
                          fontWeight: "300",
                        }}
                      >
                        · 최소 1개의 번호
                      </div>
                      <div
                        style={{
                          fontSize: "16px",
                          color: "#000000",
                          fontWeight: "300",
                        }}
                      >
                        · 최소 1개의 특수 문자 !@#$%^&*()_+|
                      </div>
                    </div>
                  </div>
                  <div className="col-auto m-0 p-0">
                    <div style={{ width: "100%", marginBottom: "20px" }}>
                      <button
                        style={{
                          width: "100%",
                          backgroundColor: "#3EA6FF",
                          color: "#fff",
                          fontSize: "17px",
                          fontWeight: "600",
                          border: "none",
                          borderRadius: "4px",
                          padding: "15px 26px",
                        }}
                        onClick={clickSignUp}
                      >
                        계속
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(ChangePW);
