import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
// import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import BellImg from "../../assets/uxnicon/icon.png";
const NoticeMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
          style={{ boxShadow: "none" }}
        >
          <img
            src={BellImg}
            style={{ width: "27px", height: "27px", cursor: "pointer" }}
          />
        </DropdownToggle>
        <DropdownMenu
          end="false"
          id="headerDropdown"
          style={{ minWidth: "330px" }}
        >
          <DropdownItem
            text
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              color: "#202020",
            }}
          >
            알림
          </DropdownItem>
          <div className="dropdown-divider" />
          <DropdownItem
            text
            style={{
              fontSize: "21px",
              color: "#202020",
              textAlign: "center",
            }}
          >
            지금은 알림이 없습니다.
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

NoticeMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(connect()(NoticeMenu));
