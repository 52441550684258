import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {atom, useRecoilState, RecoilEnv} from 'recoil';
import { faHandMiddleFinger } from '@fortawesome/free-solid-svg-icons';

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;

const searchValueState = atom({
  key:'searchValue',
  default:''
});

const SearchTextfield = () => {
  const [searchValue, setSearchValue] = useRecoilState(searchValueState);
  const handleValueChange = (event) => {
    setSearchValue(event.target.value);
  }
  return (
    <TextField id="outlined-basic" 
              label="검색" variant="outlined" 
              style={{width:'100%',backgroundColor:'#FFFFFF'}} 
              value={searchValue} 
              onChange={handleValueChange}  
              size="small"
              color="secondary"/>
  );
}

export default SearchTextfield;
