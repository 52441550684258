import React, { useEffect, useRef, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Card, Col, Row, Table } from "reactstrap";
import { VictoryArea, VictoryChart, VictoryLine, VictoryAxis } from "victory";
import "./bloodsugar.scss";
import VicBarChart from "../Charts/VicBarChart";
import VicLineCharts from "../Charts/VicLineCharts";
import ApexCharts from "../Charts/ApexCharts";
import { requestGet } from "../../apis/request";
import Loading from "../../assets/uxnicon/loadingIcon.png";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { percentData, userID, agpData } from "../../states/recoilState";
import moment from "moment";
import PrintScreenPagecopy from "./PrintScreenPagecopy";
import { useReactToPrint } from "react-to-print";

const BloodSugarPattern = () => {
  const [loading, setLoading] = useState(true);
  const idx = useRecoilValue(userID);
  const [charts, setCharts] = useState({});
  const [agps, setAgps] = useState({});

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <React.Fragment>
      <div className="wrap" ref={componentRef}>
        <button
          type="button"
          className="bg-gray-500 border border-gray-500 p-2 mb-4 printHIdeBtn"
          onClick={handlePrint}
          style={{
            position: "absolute",
            top: "130px",
            right: "25px",
            backgroundColor: "#5b5d5f",
            color: "#fff",
            borderRadius: "8px",
          }}
        >
          {" "}
          Print{" "}
        </button>
        {!loading ? (
          <div
            className="divsWrap"
            style={{ padding: "36px 147px 114px 168px" }}
          >
            <div className="m-0 p-0 w-100 row">
              <div className="m-0 p-0 col">
                <div className="m-0 p-0 w-100 row d-flex flex-column">
                  <div className="m-0 p-0 col">
                    <p className="m-0 fs-6" style={{ color: "#2A2D30" }}>
                      {idx.patientName}
                    </p>
                  </div>
                  <div
                    className="m-0 p-0 col d-flex"
                    style={{ alignItems: "baseline" }}
                  >
                    <p
                      style={{
                        fontSize: "10px",
                        color: "#2A2D30",
                        margin: "0px 9px 0px 0px",
                      }}
                    >
                      DOB:
                    </p>
                    <p
                      className="m-0"
                      style={{ fontSize: "15px", color: "#2A2D30" }}
                    >
                      {idx.patientBirth}
                    </p>
                  </div>
                </div>
              </div>
              <div className="m-0 p-0 col">
                <div className="m-0 p-0 w-100 row d-flex flex-column">
                  <div
                    className="m-0 p-0 col d-flex"
                    style={{ alignItems: "baseline" }}
                  >
                    <p
                      style={{
                        fontSize: "10px",
                        color: "#2A2D30",
                        margin: "0px 9px 0px 0px",
                      }}
                    >
                      MRN :
                    </p>
                    <p
                      className="m-0 fs-6"
                      style={{ fontSize: "15px", color: "#2A2D30" }}
                    ></p>
                  </div>
                  <div
                    className="m-0 p-0 col d-flex"
                    style={{ alignItems: "baseline" }}
                  >
                    <p
                      style={{
                        fontSize: "10px",
                        color: "#2A2D30",
                        margin: "0px 9px 0px 0px",
                      }}
                    >
                      장치 :
                    </p>
                    <p
                      className="m-0"
                      style={{ fontSize: "15px", color: "#2A2D30" }}
                    >
                      AGMS T1x
                    </p>
                  </div>
                </div>
              </div>
              <div className="m-0 p-0 col"></div>
            </div>
            <div className="m-0 p-0 w-100 row">
              <div
                className="m-0 p-0 col fs-2 fw-bold"
                style={{ color: "#2A2D30" }}
              >
                혈당 패턴 정보
              </div>
            </div>
            <div
              className="p-0 w-100 row"
              style={{ margin: "0px 0px 17px 0px" }}
            >
              <div className="m-0 p-0 col" style={{ color: "#2A2D30" }}>
                {moment(charts?.startDate).format("YYYY년MM월DD일")} ~{" "}
                {moment(charts?.endDate).format("YYYY년MM월DD일")} (
                {agps?.dateRange}일)
              </div>
            </div>
            <div className="m-0 p-0 w-100 row justify-content-between">
              <div className="m-0 col-5">
                <div
                  className="fs-6 fw-bold"
                  style={{
                    color: "#fff",
                    backgroundColor: "#000000",
                    padding: "4px 8px",
                  }}
                >
                  범위 내 시간
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ marginTop: "33px" }}
                >
                  <VicBarChart
                    data={{
                      low: agps?.lowGlucosePercent,
                      verylow: agps?.veryLowGlucosePercent,
                      target: agps?.targetGlucosePercent,
                      high: agps?.highGlucosePercent,
                      veryhigh: agps?.veryHighGlucosePercent,
                    }}
                  />
                  <div className="w-100">
                    <div className="d-flex justify-content-between">
                      <div>매우 높음</div>
                      <div>{`${agps?.veryHighGlucosePercent}%`}</div>
                    </div>
                    <div
                      className="d-flex justify-content-between"
                      style={{
                        borderBottom: "1px dashed #707070",
                        marginBottom: "10px",
                      }}
                    >
                      <div>{`‪>250 ‬mg/dL`}</div>
                      <div>{`‏‪(${agps?.veryHighGlucoseMinute}분)`}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>높음</div>
                      <div>{`${agps?.highGlucosePercent}%`}</div>
                    </div>
                    <div
                      className="d-flex justify-content-between"
                      style={{
                        borderBottom: "1px dashed #707070",
                        marginBottom: "33px",
                      }}
                    >
                      <div>181 - 250 ‬mg/dL</div>
                      <div>{`‏‪(${agps?.highGlucoseMinute}분)`}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>목표 범위</div>
                      <div>{`${agps?.targetGlucosePercent}%`}</div>
                    </div>
                    <div
                      className="d-flex justify-content-between"
                      style={{
                        borderBottom: "1px dashed #707070",
                        marginBottom: "49px",
                      }}
                    >
                      <div>70 - 180 ‬mg/dL</div>
                      <div>{`‏‪(${agps?.targetGlucoseMinute}분)`}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>낮음</div>
                      <div>{`${agps?.lowGlucosePercent}%`}</div>
                    </div>
                    <div
                      className="d-flex justify-content-between"
                      style={{
                        borderBottom: "1px dashed #707070",
                        marginBottom: "10px",
                      }}
                    >
                      <div>54 - 69 ‬mg/dL</div>
                      <div>{`‏‪(${agps?.lowGlucoseMinute}분)`}‬</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>매우 낮음</div>
                      <div>{`${agps?.veryLowGlucosePercent}%`}</div>
                    </div>
                    <div
                      className="d-flex justify-content-between"
                      style={{
                        borderBottom: "1px dashed #707070",
                      }}
                    >
                      <div>{`‪<54 ‬mg/dL`}</div>
                      <div>{`‏‪(${agps?.veryLowGlucoseMinute}분)`}‬</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="m-0 col-6">
                <div
                  className="fs-6 fw-bold"
                  style={{
                    color: "#fff",
                    backgroundColor: "#000000",
                    padding: "4px 8px",
                  }}
                >
                  혈당 통계
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{
                    padding: "13px 0px",
                    borderBottom: "1px solid #707070",
                  }}
                >
                  <div>
                    <p className="m-0">평균 혈당</p>
                    <p className="m-0">{agps?.averageGlucose}mg/dL</p>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{
                    padding: "13px 0px",
                    borderBottom: "1px solid #707070",
                  }}
                >
                  <div>
                    <p
                      className="m-0"
                      style={{ fontSize: "14px", color: "#2A2D30" }}
                    >
                      혈당 관리 지표(GMI)
                    </p>
                    <p
                      className="m-0"
                      style={{ fontSize: "14px", color: "#2A2D30" }}
                    >
                      평균 CGM 혈당 수준을 기준으로 한 대략적인 AIC 수준.
                    </p>
                    <p
                      className="m-0"
                      style={{ fontSize: "14px", color: "#2A2D30" }}
                    >
                      {agps?.gmi}% | {agps?.gmiMol}mmol/mol
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="m-0 p-0 w-100 row flex-column">
              <div
                className="m-0 p-0 w-100 col"
                style={{ color: "#fff", backgroundColor: "#000000" }}
              >
                임상의를 위한 고려사항
              </div>
              <div
                className="m-0 p-0 w-100 col"
                style={{
                  color: "#2A2D30",
                  fontSize: "13px",
                  marginBottom: "42px",
                }}
              >
                가장 중요한 패턴 :
                <span>{agps?.importantPattern?.kindKeyword}</span>
              </div>
              <div style={{ height: "320px" }}>
                <div className="row m-0 p-0">
                  <div className="col-6">
                    <div>약물</div>
                  </div>
                  <div className="col-6">
                    <div>생활방식</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="m-0 p-0 w-100 row flex-column">
              <div
                className="m-0 p-0 w-100 col"
                style={{ color: "#fff", backgroundColor: "#000000" }}
              >
                혈당패턴
              </div>
              <div className="m-0 p-0 w-100 col">
                <div style={{ height: "320px" }}>
                  <VictoryChart width={1000}>
                    <VictoryArea
                      style={{
                        data: {
                          fill: "#56C79C",
                          fillOpacity: 0.4,
                          strokeWidth: 0,
                        },
                      }}
                      interpolation="natural"
                      data={charts?.chartData1}
                    />
                    <VictoryArea
                      style={{
                        data: {
                          fill: "#56C79C",
                          fillOpacity: 0.7,
                          strokeWidth: 0,
                        },
                      }}
                      interpolation="natural"
                      data={charts?.chartData2}
                    />
                    <VictoryLine
                      style={{
                        data: { stroke: "#56C79C" },
                        parent: { border: "1px solid #56C79C" },
                      }}
                      interpolation="natural"
                      data={charts?.chartData3}
                    />
                    <VictoryAxis
                      dependentAxis
                      tickCount={10}
                      crossAxis={false}
                    />
                    <VictoryAxis
                      tickValues={charts?.dates}
                      tickCount={8}
                      crossAxis={false}
                    />
                  </VictoryChart>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="divsWrap"
            style={{
              padding: "36px 147px",
              position: "absolute",
              left: 0,
              top: 0,
              width: "100vw",
              height: "100vh",
              zIndex: 10001,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={Loading}
                alt="loading..."
                style={{
                  width: "50px",
                  height: "50px",
                  animation: ".75s linear infinite spinner-border",
                }}
              />
            </div>
          </div>
        )}
      </div>
      <PrintScreenPagecopy
        setCharts={setCharts}
        charts={charts}
        setAgps={setAgps}
        agps={agps}
        setLoading={setLoading}
      />
    </React.Fragment>
  );
};
export default withRouter(BloodSugarPattern);
