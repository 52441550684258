import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

const SplineAreaChart = ({ data, time, day }) => {
  const [lineData, setLineData] = useState([]);
  useEffect(() => {
    let arr = [];
    for (let i = 0; i < data?.length; i++) {
      arr.push({
        x: time[i],
        y: data[i],
      });
    }
    setLineData(arr);
  }, [data, time]);
  const series = [
    {
      name: day,
      data: lineData,
    },
  ];

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    yaxis: {
      min: 0,
      max: 360,
    },
    annotations: {
      position: "back",
      yaxis: [
        {
          label: {
            text: " ",
          },
          y: 180,
          y2: 70,
          fillColor: "#cccccc",
        },
      ],
    },

    colors: ["#556ee6"],
    xaxis: {
      tickAmount: 23,
      // min: new Date("2021-10-01T08:42:50.453Z").getTime(),
      // max: new Date("2021-10-10T08:42:50.453Z").getTime(),
    },
    chart: {
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    // tooltip: {
    //   x: {
    //     format: "dd/MM/yy HH:mm",
    //   },
    // },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="350"
      className="apex-charts"
      dir="ltr"
      height="100%"
      width="100%"
    />
  );
};

export default SplineAreaChart;
