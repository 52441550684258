import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, Col, Row, Table } from "reactstrap";
import { ReactComponent as Raindrops } from "../../assets/uicons-regular-rounded/svg/fi-rr-raindrops.svg";
import { ReactComponent as Apple } from "../../assets/uicons-regular-rounded/svg/fi-rr-apple.svg";
import { ReactComponent as Syringe } from "../../assets/uicons-regular-rounded/svg/fi-rr-syringe.svg";
import { ReactComponent as Wifi } from "../../assets/uicons-regular-rounded/svg/fi-rr-wifi-alt.svg";
import { ReactComponent as Add } from "../../assets/uicons-regular-rounded/svg/fi-rr-add.svg";
import { VictoryArea, VictoryChart, VictoryLine, VictoryAxis } from "victory";
import "./bloodsugar.scss";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  percentData,
  userID,
  agpData,
  appointments,
} from "../../states/recoilState";
import SplineAreaChart from "../Charts/SplineAreaChart";
import { requestGet } from "../../apis/request";
import Loading from "../../assets/uxnicon/loadingIcon.png";
import SplineAreaSnapLowChart from "../Charts/SplineAreaSnapLowChart";
import moment from "moment";
import PrintScreenPagecopy from "./PrintScreenPagecopy";
import { useReactToPrint } from "react-to-print";
const SnapShot = () => {
  const [data, setData] = useState(null);
  const [lineData, setLineData] = useState([]);
  const [titleData, setTitleData] = useState([]);
  const [sensorlineData, setSensorLineData] = useState([]);
  const [sensortitleData, setSensorTitleData] = useState([]);
  const [loading, setLoading] = useState(true);
  const idx = useRecoilValue(userID);
  const [charts, setCharts] = useState({});
  const [agps, setAgps] = useState({});

  const apicall = async () => {
    try {
      const res = await requestGet(
        `/api/v1/staff/snap-shot/${idx.patientName}`
      );
      setData(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    apicall();
  }, []);

  useEffect(() => {
    if (data?.lowBloodSugarDtoList?.length > 0) {
      let arr = [];
      let arr1 = [];
      for (let i = 0; i < data.lowBloodSugarDtoList?.length; i++) {
        arr.push({
          x: moment(data.lowBloodSugarDtoList[i]?.lowBloodSugarTime).format(
            "YYYY-MM-DD HH:mm"
          ),
          y: data.lowBloodSugarDtoList[i]?.lowDiabetes,
        });
        arr1.push(
          moment(data.lowBloodSugarDtoList[i]?.lowBloodSugarTime).format(
            "YYYY-MM-DD HH:mm"
          )
        );
      }
      setLineData(arr);
      setTitleData(arr1);
    }
  }, [data]);

  useEffect(() => {
    if (data?.timeActivateList?.length > 0) {
      let arr = [];
      let arr1 = [];
      for (let i = 0; i < data.timeActivateList?.length; i++) {
        arr.push({
          x: data.timeActivateList[i]?.time,
          y: data.timeActivateList[i]?.activatePercent,
        });
        arr1.push(data.timeActivateList[i]?.time);
      }
      setSensorLineData(arr);
      setSensorTitleData(arr1);
    }
  }, [data]);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <React.Fragment>
      <div className="wrap" ref={componentRef}>
        <button
          type="button"
          className="bg-gray-500 border border-gray-500 p-2 mb-4 printHIdeBtn"
          onClick={handlePrint}
          style={{
            position: "absolute",
            top: "130px",
            right: "25px",
            backgroundColor: "#5b5d5f",
            color: "#fff",
            borderRadius: "8px",
          }}
        >
          {" "}
          Print{" "}
        </button>
        {!loading ? (
          <div
            className="divsWrap"
            style={{ padding: "36px 147px 114px 168px" }}
          >
            <div className="m-0 p-0 w-100 row">
              <div className="m-0 p-0 col">
                <div className="m-0 p-0 w-100 row d-flex flex-column">
                  <div className="m-0 p-0 col">
                    <p className="m-0 fs-6" style={{ color: "#2A2D30" }}>
                      {idx.patientName}
                    </p>
                  </div>
                  <div
                    className="m-0 p-0 col d-flex"
                    style={{ alignItems: "baseline" }}
                  >
                    <p
                      style={{
                        fontSize: "10px",
                        color: "#2A2D30",
                        margin: "0px 9px 0px 0px",
                      }}
                    >
                      DOB:
                    </p>
                    <p
                      className="m-0"
                      style={{ fontSize: "15px", color: "#2A2D30" }}
                    >
                      {idx.patientBirth}
                    </p>
                  </div>
                </div>
              </div>
              <div className="m-0 p-0 col">
                <div className="m-0 p-0 w-100 row d-flex flex-column">
                  <div
                    className="m-0 p-0 col d-flex"
                    style={{ alignItems: "baseline" }}
                  >
                    <p
                      style={{
                        fontSize: "10px",
                        color: "#2A2D30",
                        margin: "0px 9px 0px 0px",
                      }}
                    >
                      MRN :
                    </p>
                    <p
                      className="m-0 fs-6"
                      style={{ fontSize: "15px", color: "#2A2D30" }}
                    ></p>
                  </div>
                  <div
                    className="m-0 p-0 col d-flex"
                    style={{ alignItems: "baseline" }}
                  >
                    <p
                      style={{
                        fontSize: "10px",
                        color: "#2A2D30",
                        margin: "0px 9px 0px 0px",
                      }}
                    >
                      장치 :
                    </p>
                    <p
                      className="m-0"
                      style={{ fontSize: "15px", color: "#2A2D30" }}
                    >
                      AGMS T1x
                    </p>
                  </div>
                </div>
              </div>
              <div className="m-0 p-0 col"></div>
            </div>
            <div className="m-0 p-0 w-100 row">
              <div
                className="m-0 p-0 col fs-2 fw-bold"
                style={{ color: "#2A2D30" }}
              >
                혈당 패턴 정보
              </div>
            </div>
            <div
              className="p-0 w-100 row"
              style={{ margin: "0px 0px 17px 0px" }}
            >
              <div className="m-0 p-0 col" style={{ color: "#2A2D30" }}>
                {moment(charts?.startDate).format("YYYY년MM월DD일")} ~{" "}
                {moment(charts?.endDate).format("YYYY년MM월DD일")} (
                {agps?.dateRange}일)
              </div>
            </div>
            <div className="m-0 p-0 w-100 row justify-content-between">
              <div className="m-0 col-12">
                {/* <div
                  className="fs-6 fw-bold"
                  style={{
                    color: "#fff",
                    backgroundColor: "#000000",
                    padding: "4px 8px",
                  }}
                >
                  범위 내 시간
                </div> */}
                <div
                  className="d-flex align-items-center"
                  style={{ marginTop: "33px" }}
                >
                  <div className="w-100">
                    <Row>
                      <Col md="8">
                        <div className="snapTitleLineWrap">
                          <Raindrops className="icons" />
                          <span
                            className="iconsTitle"
                            style={{ width: "70px" }}
                          >
                            혈당
                          </span>
                          <div className="titleLine" />
                        </div>
                        <Row>
                          <Col md="5">
                            <div
                              className="bloodDatesRowDiv"
                              style={{
                                justifyContent: "end",
                                position: "relative",
                                right: "-65px",
                              }}
                            >
                              <span className="snapDate">평균 혈당</span>
                            </div>
                          </Col>
                        </Row>
                        <Row className="snapRow">
                          <Col md="5">
                            <Card
                              className="snapCard"
                              style={{
                                height: "287px",
                                padding: "56px 30px 55px 30px",
                              }}
                            >
                              <Table>
                                <tbody>
                                  <tr>
                                    <th scope="row" className="tableText">
                                      평균 혈당
                                    </th>
                                    <td className="tableTextRight">
                                      {data
                                        ? Number(data?.average).toFixed(1)
                                        : "0"}
                                      mg/dL
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row" className="tableText">
                                      목표값 초과 %
                                    </th>
                                    <td className="tableTextRight">
                                      {data?.excess_percent}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row" className="tableText">
                                      목표값의 %
                                    </th>
                                    <td className="tableTextRight">
                                      {data?.goal_percent}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row" className="tableText">
                                      목표값 미만 %
                                    </th>
                                    <td className="tableTextRight">
                                      {data?.under_percent}%
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Card>
                          </Col>
                          <Col md="7">
                            <Card
                              className="snapCard"
                              style={{
                                height: "287px",
                                padding: "54px 42px 56px 44px",
                              }}
                            >
                              <div style={{ height: "100%" }}>
                                {/* <SplineAreaChart /> */}
                                <VictoryChart
                                  maxDomain={{ y: 350 }}
                                  minDomain={{ y: 0 }}
                                >
                                  <VictoryArea
                                    style={{
                                      data: {
                                        fill: "#56C79C",
                                        fillOpacity: 0.3,
                                        strokeWidth: 0,
                                      },
                                    }}
                                    interpolation="natural"
                                    data={charts?.chartData1}
                                  />
                                  <VictoryLine
                                    style={{
                                      data: { stroke: "#56C79C" },
                                      parent: { border: "1px solid #56C79C" },
                                    }}
                                    interpolation="natural"
                                    data={charts?.chartData3}
                                  />
                                  <VictoryAxis
                                    dependentAxis
                                    tickCount={10}
                                    crossAxis={false}
                                  />
                                  <VictoryAxis
                                    // tickValues={titleData}
                                    tickCount={1}
                                    crossAxis={false}
                                  />
                                </VictoryChart>
                              </div>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="5">
                            <div
                              className="bloodDatesRowDiv"
                              style={{
                                justifyContent: "end",
                                position: "relative",
                                right: "-73px",
                              }}
                            >
                              <span className="snapDate">저혈당 증상</span>
                            </div>
                          </Col>
                        </Row>
                        <Row className="snapRow">
                          <Col md="5">
                            <Card
                              className="snapCard"
                              style={{
                                height: "287px",
                                padding: " 104.5px 30px 104.5px 30px",
                              }}
                            >
                              <Table>
                                <tbody>
                                  <tr>
                                    <th scope="row" className="tableText">
                                      저혈당 증상
                                    </th>
                                    <td className="tableTextRight">
                                      {data?.low_blood_sugar_count}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row" className="tableText">
                                      평균 지속 시간
                                    </th>
                                    <td className="tableTextRight">
                                      {data?.low_blood_sugar_duration}분
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Card>
                          </Col>
                          <Col md="7">
                            <Card
                              className="snapCard"
                              style={{
                                height: "287px",
                                padding: "54px 42px 56px 44px",
                              }}
                            >
                              <div style={{ height: "100%" }}>
                                <VictoryChart
                                  maxDomain={{ y: 100 }}
                                  minDomain={{ y: 40 }}
                                >
                                  <VictoryArea
                                    style={{
                                      data: {
                                        fill: "#56C79C",
                                        fillOpacity: 0.3,
                                        strokeWidth: 0,
                                      },
                                    }}
                                    interpolation="natural"
                                    data={lineData}
                                  />
                                  {/* <VictoryLine
                          style={{
                            data: { stroke: "#56C79C" },
                            parent: { border: "1px solid #56C79C" },
                          }}
                          interpolation="natural"
                          data={[
                            { x: "00:00", y: 70 },
                            { x: "06:00", y: 70 },
                            { x: "12:00", y: 70 },
                            { x: "18:00", y: 70 },
                            { x: "24:00", y: 70 },
                          ]}
                        /> */}
                                  <VictoryAxis
                                    dependentAxis
                                    tickCount={10}
                                    crossAxis={false}
                                  />
                                  <VictoryAxis
                                    tickValues={titleData}
                                    tickCount={2}
                                    crossAxis={false}
                                  />
                                </VictoryChart>
                                {/* <SplineAreaSnapLowChart
                        data={data?.lowBloodSugarDtoList}
                      /> */}
                              </div>
                            </Card>
                          </Col>
                        </Row>
                        <div className="snapTitleLineWrap">
                          <Wifi className="icons" />
                          <span
                            className="iconsTitle"
                            style={{ width: "130px" }}
                          >
                            센서 사용
                          </span>
                          <div className="titleLine" />
                        </div>
                        <Row>
                          <Col md="5">
                            <div
                              className="bloodDatesRowDiv"
                              style={{
                                justifyContent: "end",
                                position: "relative",
                                right: "-85px",
                              }}
                            >
                              <span className="snapDate">시간 센서 활성</span>
                            </div>
                          </Col>
                        </Row>
                        <Row className="snapRow">
                          <Col md="5">
                            <Card
                              className="snapCard"
                              style={{
                                height: "287px",
                                padding: "104.5px 30px 104.5px 30px",
                              }}
                            >
                              <Table>
                                <tbody>
                                  <tr>
                                    <th scope="row" className="tableText">
                                      % 시간 센서 활성
                                    </th>
                                    <td className="tableTextRight">
                                      {data?.time_sensor_activate}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row" className="tableText">
                                      평균 스캔/조회
                                    </th>
                                    <td className="tableTextRight">
                                      {data?.scan_average}/일
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Card>
                          </Col>
                          <Col md="7">
                            <Card
                              className="snapCard"
                              style={{
                                height: "287px",
                                padding: "54px 42px 56px 44px",
                              }}
                            >
                              <div style={{ height: "100%" }}>
                                <VictoryChart>
                                  <VictoryArea
                                    style={{
                                      data: {
                                        fill: "#56C79C",
                                        fillOpacity: 0.3,
                                        strokeWidth: 0,
                                      },
                                    }}
                                    interpolation="natural"
                                    data={sensorlineData}
                                  />
                                  <VictoryAxis
                                    dependentAxis
                                    tickCount={10}
                                    crossAxis={false}
                                  />
                                  <VictoryAxis
                                    tickValues={sensortitleData}
                                    tickCount={5}
                                    crossAxis={false}
                                  />
                                </VictoryChart>
                              </div>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="4">
                        <Row>
                          <Col>
                            <div className="snapTitleLineWrap">
                              <Apple className="icons" />
                              <span
                                className="iconsTitle"
                                style={{ width: "140px" }}
                              >
                                탄수화물
                              </span>
                              <div className="titleLine" />
                            </div>
                            <div className="bloodDatesRowDiv dflex justify-content-center">
                              <span className="snapDate">일일 탄수화물</span>
                            </div>
                            <Card
                              className="bloodSugarCards"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "287px",
                                padding: "85px 150px 90px",
                              }}
                            >
                              <span className="bloodDataCardDivNum">000</span>
                              <span className="bloodDataCardDivSpan">
                                그램/일
                              </span>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="snapTitleLineWrap">
                              <Syringe className="icons" />
                              <span
                                className="iconsTitle"
                                style={{ width: "110px" }}
                              >
                                인슐린
                              </span>
                              <div className="titleLine" />
                            </div>
                            <div className="bloodDatesRowDiv d-flex justify-content-center">
                              <span className="snapDate">초속효성 인슐린</span>
                            </div>
                            <Card
                              className="bloodSugarCards"
                              style={{
                                height: "287px",
                                padding: "59.5px 74.2px 51.5px",
                              }}
                            >
                              <Table>
                                <tbody>
                                  <tr>
                                    <th scope="row" className="tableText">
                                      식사
                                    </th>
                                    <td className="tableTextRight">0</td>
                                  </tr>
                                  <tr>
                                    <th scope="row" className="tableText">
                                      수정
                                    </th>
                                    <td className="tableTextRight">0</td>
                                  </tr>
                                  <tr>
                                    <th scope="row" className="tableText">
                                      사용자 변경사항
                                    </th>
                                    <td className="tableTextRight">0</td>
                                  </tr>
                                  <tr>
                                    <th scope="row" className="tableText">
                                      수동
                                    </th>
                                    <td className="tableTextRight">0</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="bloodDatesRowDiv d-flex justify-content-center">
                              <span className="snapDate">지속형 인슐린</span>
                            </div>
                            <Card
                              className="bloodSugarCards"
                              style={{
                                height: "287px",
                                padding: "59.5px 74.2px 51.5px",
                              }}
                            >
                              <Table>
                                <tbody>
                                  <tr>
                                    <th scope="row" className="tableText">
                                      식사
                                    </th>
                                    <td className="tableTextRight">0</td>
                                  </tr>
                                  <tr>
                                    <th scope="row" className="tableText">
                                      수정
                                    </th>
                                    <td className="tableTextRight">0</td>
                                  </tr>
                                  <tr>
                                    <th scope="row" className="tableText">
                                      사용자 변경사항
                                    </th>
                                    <td className="tableTextRight">0</td>
                                  </tr>
                                  <tr>
                                    <th scope="row" className="tableText">
                                      수동
                                    </th>
                                    <td className="tableTextRight">0</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="bloodDatesRowDiv d-flex justify-content-center">
                              <span className="snapDate">총 일일 인슐린</span>
                            </div>
                            <Card
                              className="bloodSugarCards"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "287px",
                                padding: "85px 150px 90px",
                              }}
                            >
                              <span className="bloodDataCardDivNum">000</span>
                              <span className="bloodDataCardDivSpan">
                                단위/일
                              </span>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="titleLineWrap">
                              <Add className="icons" />
                              <span
                                className="iconsTitle"
                                style={{ width: "80px" }}
                              >
                                주석
                              </span>
                              <div className="titleLine" />
                            </div>
                            <Card
                              className="snapCard"
                              style={{ padding: "59.5px 30px 353.5px 38px" }}
                            >
                              <div
                                className="tableText"
                                style={{ marginBottom: "24px" }}
                              >
                                인슐린 데이터에 격차가 있습니다. 이 보고 기간 의
                                14일 동안 인슐린 증상이 기록되지 않았습니다.
                              </div>
                              <div className="tableText">
                                인슐린 데이터에 격차가 있습니다. 이 보고 기간 의
                                14일 동안 인슐린 증상이 기록되지 않았습니다.
                              </div>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="divsWrap"
            style={{
              padding: "36px 147px",
              position: "absolute",
              left: 0,
              top: 0,
              width: "100vw",
              height: "100vh",
              zIndex: 10001,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={Loading}
                alt="loading..."
                style={{
                  width: "50px",
                  height: "50px",
                  animation: ".75s linear infinite spinner-border",
                }}
              />
            </div>
          </div>
        )}
      </div>
      <PrintScreenPagecopy
        setCharts={setCharts}
        charts={charts}
        setAgps={setAgps}
        agps={agps}
        setLoading={setLoading}
      />
    </React.Fragment>
  );
};
export default withRouter(SnapShot);
