import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, Col, Container, Input, Row, Form, Button } from "reactstrap";
import "./lineupmodal.scss";

const ChangePWModal = ({ changePW, setChangePW }) => {
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const closeXModal = (e) => {
    setChangePW(!changePW);
  };

  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center">
          <Col>
            <Form style={{ display: "flex", justifyContent: "center" }}>
              <div className="modalOverlay" />
              <Card className="modalPWCard">
                <Row className="w-100" style={{ margin: "0px 0px 15px 0px" }}>
                  <Col
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    새 비밀번호를 입력하고 계속을 누릅니다.
                  </Col>
                </Row>
                <Row className="w-100" style={{ margin: "0px 0px 22px 0px" }}>
                  <Col xs="12" md="5" style={{ paddingRight: "15px" }}>
                    <Row style={{ marginBottom: "14px" }}>
                      <Col style={{ fontSize: "19px" }}>새 비밀번호</Col>
                    </Row>
                    <Row style={{ marginBottom: "15px" }}>
                      <Col>
                        <Input
                          className="changePWInput"
                          placeholder="비밀번호"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Input
                          className="changePWInput"
                          placeholder="비밀번호 다시 입력"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" md="7" style={{ paddingLeft: "15px" }}>
                    <Row style={{ marginBottom: "7px" }}>
                      <Col style={{ fontSize: "19px" }}>비밀번호 요구사항</Col>
                    </Row>
                    <Row>
                      <Col>
                        <Row>
                          <Col>· 8~36자</Col>
                        </Row>
                        <Row>
                          <Col>· 최소 1개의 번호</Col>
                        </Row>
                        <Row style={{ marginBottom: "15px" }}>
                          <Col>{`· 최소 1개의 특수 문자 !@#$%^&*()_+|`}</Col>
                        </Row>
                        <Row>
                          <Col className="d-flex justify-content-end">
                            <Button
                              style={{
                                border: "none",
                                backgroundColor: "#3EA6FF",
                                borderRadius: "8px",
                                padding: "15px 40px",
                                color: "#fff",
                                fontWeight: "600",
                              }}
                              onClick={() => closeXModal()}
                            >
                              계속
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(ChangePWModal);
