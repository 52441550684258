import React, { useEffect, useRef, useState } from "react";
import { withRouter, useHistory, useLocation} from "react-router-dom";

import RangeAreaChart from './Charts/RangeAreaChart';
import StackedBarChart from "./Charts/StackedBarChart";

import { requestGet, agmsClientRequestGet, agmsClientRequestPost } from "../../apis/request";
import {atom, useRecoilState} from 'recoil';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import secureLocalStorage from "react-secure-storage";
import {isCurrentBloodSugarAtom, seletedPatientIdState, useRefStateAtom} from '../../states/recoilState';




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    //color: theme.palette.text.secondary,
    border: '1.5px solid #5E2589',
  }));



const CGMreport = () => {
    const [useRefState, setUseRefState] = useRecoilState(useRefStateAtom);
    const componentRef = useRef();

    const history = useHistory();
    const location = useLocation();
    const [dataObject, setDataObject] = useState({
        hospitalName: '',
        patientName: '',
        identification: '',
        startDate: '',
        endDate: '',
        deviceType: '',
        deviceSerialNumber: '',
        activePercent: '',
        measurementPeriod: 1,

        avgGlucose: null,
        maxGlucose: null,
        minGlucose: null,
        measurementPeriod: null,
        exceedUpperPercent: null,
        exceedUpperTime: null,
        exceedLowerPercent: null,
        exceedLowerTime: null,
        totalMeasurementTime: '0',
    });
    const [stackedGraphData, setStackedGraphData] = useState({
        countVeryHighPercent: 0,
        countHighPercent: 0,
        countTargetPercent: 0,
        countLowPercent: 0,
        countVeryLowPercent: 0,
    });

    const [selectedPatientId, setSeletedPatientId] = useRecoilState(seletedPatientIdState);
    const [isCurrentBloodSugar, setIsCurrentBloodSugar] = useRecoilState(isCurrentBloodSugarAtom);


    //요약 그래프용
    const [lineSeriesData, setLineSeriesData] = useState([]);
    const [middleAreaSeriesData, setMiddleAreaSeriesData] = useState([]);
    const [mainUpperAreaSeriesData, setUpperMainAreaSeriesData] = useState([]);
    const [mainLowerAreaSeriesData, setLowerMainAreaSeriesData] = useState([]);

    const [upperLimit, setUpperLimit]= useState([]);
    const [lowerLimit, setLowerLimit]= useState([]);

    const callCGMreportApi = async (input) => {
        let result = null;
        if(!isCurrentBloodSugar){
            result = await agmsClientRequestGet(`/api/report/statistic/${input}`);
        }else{
            result = await agmsClientRequestGet(`/api/report/statistic/${input}?strValueType=1308`);
        }
        
        setUpperLimit(result.upperLimit);
        setLowerLimit(result.lowerLimit);

        //Area그래프에 props로 넘겨줄 데이터를 가공합니다.
        if(result !== undefined){

            let tempLineList = [];
            let tempMiddelAreaList = [];
            let tempUpperMainAreaList = [];
            let tempLowerMainAreaList = [];

            for(let i = 0; i < result.profileGraphData["50%"].length; i+=4) {
                let beforeUtcTempDate = new Date("2022-01-02T00:00:00");
                let tempDate = new Date(Date.UTC(beforeUtcTempDate.getFullYear(), beforeUtcTempDate.getMonth(),beforeUtcTempDate.getDate(),beforeUtcTempDate.getHours(),beforeUtcTempDate.getMinutes(),beforeUtcTempDate.getSeconds()));
                tempDate.setMinutes(tempDate.getMinutes() + 15 * i);

                let tempObject = {
                x: tempDate,
                y: Math.floor(result.profileGraphData["50%"][i])
                }
                tempLineList.push(tempObject);
                
                let tempObject1 = {
                x: tempDate,
                y: [ Math.floor(result.profileGraphData["25%"][i]),  Math.floor(result.profileGraphData["75%"][i]) ] 
                }
                tempMiddelAreaList.push(tempObject1);

                let tempObject2 = {
                x: tempDate,
                y: [ Math.floor(result.profileGraphData["75%"][i]),  Math.floor(result.profileGraphData["95%"][i]) ] 
                }
                tempUpperMainAreaList.push(tempObject2);

                let tempObject3 = {
                x: tempDate,
                y: [ Math.floor(result.profileGraphData["5%"][i]),  Math.floor(result.profileGraphData["25%"][i]) ] 
                }
                tempLowerMainAreaList.push(tempObject3);

                setLineSeriesData(tempLineList);
                setMiddleAreaSeriesData(tempMiddelAreaList);
                setUpperMainAreaSeriesData(tempUpperMainAreaList);
                setLowerMainAreaSeriesData(tempLowerMainAreaList);
            }

           
        }


        setDataObject(result);

        setStackedGraphData(
            {
                countVeryHighPercent: result.countVeryHighPercent,
                countHighPercent: result.countHighPercent,
                countTargetPercent: result.countTargetPercent,
                countLowPercent: result.countLowPercent,
                countVeryLowPercent: result.countVeryLowPercent,
            }
        );
    }

    // useEffect(()=>{
        
    // },[stackedGraphData]);

    // useEffect(()=>{        
    //     callCGMreportApi(secureLocalStorage.getItem("selectedPatientId"));
    // },[selectedPatientId]);

    useEffect(()=>{ 
        callCGMreportApi(secureLocalStorage.getItem("selectedPatientId"));
        setUseRefState(componentRef.current);
    },[]);

    const handleToWeeklyChart = () =>{
        history.push('/weeklyChartList/list');
    }

    return (
    <div ref={componentRef}>
        <div
            className="row w-100"
            style={{ padding: "0px 70px", marginTop: "30px", height:'1100px' }}
        >
            <div className="panel-body"  style={{maxWidth:'1500px', minWidth:'1500px', marginLeft:'auto', marginRight:'auto'}}>
                <Stack spacing={{ xs: 1, sm: 2 }} style={{ height:'3000'}}>
                    <Box style={{width: '100%', display:'flex'}}>
                        <Card style={{flex : 1 , display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', border: '1px solid #5E2589', marginRight:'10px'}}>
                                <div style={{width:'100%', flex : 1, paddingLeft:'30px', display:'flex', alignItems:'center', fontSize:'16px'}}>
                                    <b>측정 기간 내 {isCurrentBloodSugar ? '혈당': '전류'} 분포</b>
                                </div>
                                <div style={{width:'100%', flex : 3, display:'flex', alignItems:'center'}}>
                                    <StackedBarChart stackedGraphData = {stackedGraphData}/>
                                </div>
                                
                        </Card>
                        <Card style={{flex : 1, marginLeft:'10px', border: '1px solid #5E2589'}}>
                            <CardContent>
                                <div style={{display:'flex', fontWeight:'bold', fontSize:'16px'}}>
                                    <div style={{flex:1}}>
                                        성명: {dataObject.patientName} 
                                    </div>
                                    <div style={{flex:1, fontWeight:'bold'}}>
                                        병원 : {dataObject.hospitalName} 
                                    </div>
                                </div>
                                
                                <hr></hr>
                                
                                <div style={{display:'flex', fontWeight:'bold', fontSize:'16px'}}>
                                    <div style={{flex: 1}}>
                                        측정기간 : {dataObject.startDate} ~ {dataObject.endDate} ({dataObject.measurementPeriod}일)
                                    </div>
                                    {/* <div style={{flex: 1}}>
                                    
                                    </div> */}
                                </div>

                                <hr></hr>

                                <div style={{display:'flex',fontWeight:'bold', fontSize:'16px'}}>
                                    <div style={{flex:1}}>
                                        기기 정보 : {dataObject.deviceType} ({dataObject.deviceSerialNumber})
                                    </div>
                                    <div style={{flex:1}}>
                                        CGM활성화율 : {dataObject.activePercent}%
                                    </div>
                                </div>
                                <hr></hr>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid xs={4}>
                                        <Item>
                                            <div>
                                                <b>평균{isCurrentBloodSugar ? '혈당': '전류'}</b>
                                            </div>
                                            <div>
                                                {Math.round(dataObject.avgGlucose)} {isCurrentBloodSugar ? 'mg/dL': 'nA'}
                                            </div>
                                        </Item>
                                    </Grid>
                                    <Grid xs={4}>
                                        <Item>
                                            <div>
                                                <b>최고{isCurrentBloodSugar ? '혈당': '전류'}</b>
                                            </div>
                                            <div>
                                                {Math.round(dataObject.maxGlucose)} {isCurrentBloodSugar ? 'mg/dL': 'nA'}
                                            </div>
                                        </Item>
                                    </Grid>
                                    <Grid xs={4}>
                                        <Item>
                                            <div>
                                                <b>최저{isCurrentBloodSugar ? '혈당': '전류'}</b>
                                            </div>
                                            <div>
                                                {Math.round(dataObject.minGlucose)} {isCurrentBloodSugar ? 'mg/dL': 'nA'}
                                            </div>
                                        </Item>
                                    </Grid>
                                    <Grid xs={4}>
                                        <Item>
                                            <div>
                                                <b>측정시간</b>
                                            </div>
                                            <div>
                                                {dataObject.totalMeasurementTime}
                                            </div>
                                        </Item>
                                    </Grid>
                                    <Grid xs={4}>
                                        <Item>
                                            <div>
                                                <b>상한치(180) 초과</b>
                                            </div>
                                            <div>
                                                {dataObject.exceedUpperPercent}% ({dataObject.exceedUpperTime})
                                            </div>
                                        </Item>
                                    </Grid>
                                    <Grid xs={4}>
                                        <Item>
                                            <div>
                                                <b>하한치(70) 미달</b>
                                            </div>
                                            <div>
                                                {dataObject.exceedLowerPercent}% ({dataObject.exceedLowerTime})
                                            </div>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                    <Box sx={{width: '100%', height:'100%'}}>
                        <Card style={{height:'100%', border: '1px solid #5E2589'}}>
                            <CardContent style={{height:'100%'}}>
                                <Box style={{display:'flex', flexDirection:'column', height:'100%'}}> 
                                    <div style={{flex:'1', border:'0px solid #000000', borderRadius:'5px', padding:'10px', backgroundColor:'rgba(180, 180, 180, 0.23)', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}> 
                                        <span style={{fontSize:'18px', fontWeight:'bold', marginLeft:'15px'}}>
                                            24시간 {isCurrentBloodSugar ? '혈당': '전류'} 데이터 통계
                                        </span>
                                        
                                        {/* <Button variant="contained" size="medium" style={{ backgroundColor : '#DCD7E2', color:'#000000', fontSize:'16px', marginLeft:'auto'}} onClick={handleToWeeklyChart}> 
                                            <b>보고서 보기</b>
                                        </Button> */}
                                    </div>
                                    <Box style={{flex:'5'}}>
                                        {/* <Card style={{height:'100%', padding:'10px', border:'#5E2589 solid 1px'}}>
                                        
                                        </Card> */}
                                        <RangeAreaChart 
                                                middleAreaSeriesData = {middleAreaSeriesData} 
                                                mainUpperAreaSeriesData = {mainUpperAreaSeriesData} 
                                                mainLowerAreaSeriesData = {mainLowerAreaSeriesData} 
                                                lineSeriesData = {lineSeriesData} 
                                                upperLimit = {upperLimit}
                                                lowerLimit = {lowerLimit}
                                                height={600}/>
                                    </Box>
                                
                                </Box>
                                
                            </CardContent>
                        </Card>
                    </Box>
                </Stack>
                
            </div>
        
        </div>
    </div>
  );
}

export default withRouter(CGMreport);