import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, Col, FormGroup, Row, Input } from "reactstrap";
import { ReactComponent as Raindrops } from "../../assets/uicons-regular-rounded/svg/fi-rr-raindrops.svg";
import { ReactComponent as Tablet } from "../../assets/uicons-regular-rounded/svg/fi-rr-tablet.svg";
import Device from "../../assets/01_galaxys21_plus_phantom_violet_front_2021-11-03/01_galaxys21_plus_phantom_violet_front.png";
import "./bloodsugar.scss";
import Loading from "../../assets/uxnicon/loadingIcon.png";
import moment from "moment";
import "moment/locale/ko";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  percentData,
  userID,
  agpData,
  appointments,
} from "../../states/recoilState";
const DeviceDetails = () => {
  const [loading, setLoading] = useState(true);
  const idx = useRecoilValue(userID);
  const agpdata = useRecoilValue(agpData);
  const chartdata = useRecoilValue(percentData);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <React.Fragment>
      <div className="wrap">
        {!loading ? (
          <div
            className="divsWrap"
            style={{ padding: "36px 147px 114px 168px" }}
          >
            <div className="m-0 p-0 w-100 row">
              <div className="m-0 p-0 col">
                <div className="m-0 p-0 w-100 row d-flex flex-column">
                  <div className="m-0 p-0 col">
                    <p className="m-0 fs-6" style={{ color: "#2A2D30" }}>
                      {idx.patientName}
                    </p>
                  </div>
                  <div
                    className="m-0 p-0 col d-flex"
                    style={{ alignItems: "baseline" }}
                  >
                    <p
                      style={{
                        fontSize: "10px",
                        color: "#2A2D30",
                        margin: "0px 9px 0px 0px",
                      }}
                    >
                      DOB:
                    </p>
                    <p
                      className="m-0"
                      style={{ fontSize: "15px", color: "#2A2D30" }}
                    >
                      {idx.patientBirth}
                    </p>
                  </div>
                </div>
              </div>
              <div className="m-0 p-0 col">
                <div className="m-0 p-0 w-100 row d-flex flex-column">
                  <div
                    className="m-0 p-0 col d-flex"
                    style={{ alignItems: "baseline" }}
                  >
                    <p
                      style={{
                        fontSize: "10px",
                        color: "#2A2D30",
                        margin: "0px 9px 0px 0px",
                      }}
                    >
                      MRN :
                    </p>
                    <p
                      className="m-0 fs-6"
                      style={{ fontSize: "15px", color: "#2A2D30" }}
                    ></p>
                  </div>
                  <div
                    className="m-0 p-0 col d-flex"
                    style={{ alignItems: "baseline" }}
                  >
                    <p
                      style={{
                        fontSize: "10px",
                        color: "#2A2D30",
                        margin: "0px 9px 0px 0px",
                      }}
                    >
                      장치 :
                    </p>
                    <p
                      className="m-0"
                      style={{ fontSize: "15px", color: "#2A2D30" }}
                    >
                      AGMS T1x
                    </p>
                  </div>
                </div>
              </div>
              <div className="m-0 p-0 col"></div>
            </div>
            <div className="m-0 p-0 w-100 row">
              <div
                className="m-0 p-0 col fs-2 fw-bold"
                style={{ color: "#2A2D30" }}
              >
                혈당 패턴 정보
              </div>
            </div>
            <div
              className="p-0 w-100 row"
              style={{ margin: "0px 0px 17px 0px" }}
            >
              <div className="m-0 p-0 col" style={{ color: "#2A2D30" }}>
                {moment(chartdata.startDate).format("YYYY년MM월DD일")} ~{" "}
                {moment(chartdata.endDate).format("YYYY년MM월DD일")} (
                {agpdata.data?.dateRange}일)
              </div>
            </div>
            <div className="m-0 p-0 w-100 row justify-content-between">
              <div className="m-0 col-12">
                {/* <div
                  className="fs-6 fw-bold"
                  style={{
                    color: "#fff",
                    backgroundColor: "#000000",
                    padding: "4px 8px",
                  }}
                >
                  범위 내 시간
                </div> */}
                <div
                  className="d-flex align-items-center"
                  style={{ marginTop: "33px" }}
                >
                  <div className="w-100">
                    <Row>
                      <div
                        className="snapTitleLineWrap"
                        style={{ marginBottom: "15px" }}
                      >
                        <Raindrops className="icons" />
                        <span className="iconsTitle" style={{ width: "130px" }}>
                          혈당 설정
                        </span>
                        <div className="titleLine" />
                      </div>
                      <Col>
                        <Card
                          className="snapCard"
                          style={{
                            marginBottom: "39px",
                            padding: "23px 54px 59.5px 24px",
                          }}
                        >
                          <div
                            className="tableText"
                            style={{ marginBottom: "30px" }}
                          >
                            다음은 세셰적 합의에 따른 권장 범위입니다. Dexcom
                            CLARITY 범위는 Dexcom CGM 경고와 다를 수 있습니다.
                            여기에서의 변경사항은 Dexcom CLARITY에만 적용
                            됩니다.
                          </div>
                          <div>
                            <span className="tableTitle">목표 범위</span>
                            <span className="tableText"> : 70-180 mg/dL</span>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <div
                        className="snapTitleLineWrap"
                        style={{ marginBottom: "10px" }}
                      >
                        <Tablet className="icons" />
                        <span className="iconsTitle" style={{ width: "70px" }}>
                          장치
                        </span>
                        <div className="titleLine" />
                      </div>
                      <Col>
                        <Card
                          className="snapCard"
                          style={{
                            padding: "35px 38px 35px 38px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img src={Device} alt="device" className="device" />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <div
                                  className="dispalyColumn"
                                  style={{ marginBottom: "42px" }}
                                >
                                  <span
                                    className="tableTitle"
                                    style={{ marginBottom: "7px" }}
                                  >
                                    혈당 장치
                                  </span>
                                  <span className="tableText">
                                    {agpdata.data?.osName}
                                  </span>
                                </div>
                                <div className="dispalyColumn">
                                  <span
                                    className="tableTitle"
                                    style={{ marginBottom: "7px" }}
                                  >
                                    소프트웨어 버전
                                  </span>
                                  <span className="tableText">
                                    {agpdata.data?.firmware}
                                  </span>
                                </div>
                              </div>
                              <div>
                                <div
                                  className="dispalyColumn"
                                  style={{ marginBottom: "42px" }}
                                >
                                  <span
                                    className="tableTitle"
                                    style={{ marginBottom: "7px" }}
                                  >
                                    OS 버전
                                  </span>
                                  <span className="tableText">
                                    {agpdata.data?.osVersion}
                                  </span>
                                </div>
                                <div className="dispalyColumn">
                                  <span
                                    className="tableTitle"
                                    style={{ marginBottom: "7px" }}
                                  >
                                    스마트폰 모델
                                  </span>
                                  <span className="tableText">
                                    {agpdata.data?.smartPhone}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="divsWrap"
            style={{
              padding: "36px 147px",
              position: "absolute",
              left: 0,
              top: 0,
              width: "100vw",
              height: "100vh",
              zIndex: 10001,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={Loading}
                alt="loading..."
                style={{
                  width: "50px",
                  height: "50px",
                  animation: ".75s linear infinite spinner-border",
                }}
              />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default withRouter(DeviceDetails);
