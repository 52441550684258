import React, {useEffect ,useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { atom, useRecoilState } from 'recoil';
import {  agmsClientRequestGet } from "../../../apis/request";

const isCurrentBloodSugarAtom = atom({
  key:'isCurrentBloodSugar',
  default: false
});

const RangeAreaChart = (props) => {  

  const [isCurrentBloodSugar, setIsCurrentBloodSugar] = useRecoilState(isCurrentBloodSugarAtom);

  const series = [
    {
      type: 'rangeArea',
      name: '25%~75%',
  
      data: props.middleAreaSeriesData
    },
  
    {
      type: 'rangeArea',
      name: '75%~95%',
      data: props.mainUpperAreaSeriesData
    },
    {
      type: 'rangeArea',
      name: '5%~25%',
      data: props.mainLowerAreaSeriesData
    },
    {
      type: 'line',
      name: '평균값',
      data: props.lineSeriesData
    },
  ];

  const options = {
    chart: {
        height: '100%',
        type: 'rangeArea',
        animations: {
          enabled: false,
          //speed: 200
        },
        toolbar:{
          show: false,
        },
        zoom:{
          enabled: false
        },
        markers:{
          size:0
        }
    },
    tooltip: {
      enabled:true,
      x:{
        show:false,
        format: '혈당값'
      },
    },
    xaxis:{
      type:'datetime'
    },
    colors: ['#A2B5D4', '#DFE3ED','#DFE3ED', '#00538F'],
    dataLabels: {
        enabled: false
    },
    fill: {
      opacity: [1, 1, 1, 1]
    },
    forecastDataPoints: {
        count: 0
    },
    stroke: {
        curve: 'smooth',
        width: [0, 0, 2, 2]
    },
    legend: {
        show: true,
        customLegendItems: ['25%~75%','75%~95%','0%~25%', '50%'],
        inversseOrder: false
    },
    title: {
        text: ''
    },
    markers: {
        hover: {
          sizeOffset: 5
        }
    },
    annotations : {
      // yaxis: [
      //   {
      //     y: 180,
      //     borderColor: '#00E396',
      //     label: {
      //       borderColor: '#00E396',
      //       style: {
      //         color: '#fff',
      //         background: '#00E396'
      //       },
      //       text: '유효범위'
      //     }
      //   },
      //   {
      //     y: 70,
      //     borderColor: '#00E396',
      //     label: {
      //       borderColor: '#00E396',
      //       style: {
      //         color: '#fff',
      //         background: '#00E396'
      //       },
      //       text: '유효범위'
      //     }
      //   },
      // ]
      yaxis:[
        // {
        //   y: 50,
        //   y2: 10,
        //   borderColor: '#000',
        //   fillColor: '#93212B',
        //   label: {
        //     text: '70'
        //   },
        //   opacity: 0.5
        // },
        // {
        //   y: 100,
        //   y2: 50,
        //   borderColor: '#000',
        //   fillColor: '#D8565B',
        //   label: {
        //     text: '50'
        //   },
        //   opacity: 0.5
        // },
        {
          y: props.upperLimit,
          y2: props.lowerLimit,
          borderColor: '#000',
          fillColor: '#7DBC7B',
          label: {
            text: '70 - 180',
            borderColor: '#8D83C6',
            style: {
              background: '#8D83C6',
              color: '#fff',
              fontSize:'13px'
            }
          },
          opacity: 0.3
        },
        // {
        //   y: 500,
        //   y2: 300,
        //   borderColor: '#000',
        //   fillColor: '#EFA92C',
        //   label: {
        //     text: '70'
        //   },
        //   opacity: 0.5
        // },
        // {
        //   y: 700,
        //   y2: 500,
        //   borderColor: '#000',
        //   fillColor: '#E77B2B',
        //   label: {
        //     text: '50'
        //   },
        //   opacity: 0.5
        // },
      ]
    }
  };

  if(isCurrentBloodSugar){
    options.yaxis = {
      min: 0,
      max: 350
    };
  }else{
    options.yaxis = {
      min: 0,
      max: 30
    };
  }

  return (
    <div style={{height:'100%', width:'100%'}}>
      <ReactApexChart options={options} series={series} type="rangeArea" height={props.height}/>
    </div>
    
  );
};

  export default RangeAreaChart;
