import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// import { ReactComponent as ThreeDots } from "../../assets/three-dots-vertical.svg";
import { ReactComponent as Pencil } from "../../assets/uicons-regular-rounded/svg/fi-rr-pencil.svg";
import { ReactComponent as Trash } from "../../assets/uicons-regular-rounded/svg/fi-rr-trash.svg";

const ProfileDropdown = ({ setEditOpen }) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
        id="bellBtnWrap"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          {/* <ThreeDots className="threeDots" /> */}
        </DropdownToggle>

        <DropdownMenu className="p-0" end>
          <DropdownItem
            className="pencilText"
            onClick={() => setEditOpen(true)}
          >
            <Pencil className="pencilIcon" />
            편집
          </DropdownItem>
          <DropdownItem className="trashText">
            <Trash className="trashIcon" />
            삭제
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
