import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import Scheduler from "../Scheduler/Scheduler";
import { ReactComponent as Left } from "../../assets/uicons-regular-rounded/svg/fi-rr-angle-small-left.svg";
import { ReactComponent as Right } from "../../assets/uicons-regular-rounded/svg/fi-rr-angle-small-right.svg";
import moment from "moment";
import "moment/locale/ko";
import "./bloodsugar.scss";
import Loading from "../../assets/uxnicon/loadingIcon.png";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  percentData,
  userID,
  agpData,
  appointments,
} from "../../states/recoilState";
import PrintScreenPagecopy from "./PrintScreenPagecopy";
import { useReactToPrint } from "react-to-print";
const MonthlySummary = () => {
  const [loading, setLoading] = useState(true);
  const [month, setMonth] = useState(moment().subtract(1, "d"));
  const idx = useRecoilValue(userID);
  const [charts, setCharts] = useState({});
  const [agps, setAgps] = useState({});
  const [appoint, setAppoint] = useState([]);
  const [appoCheck] = useState(true);
  const LeftMonthBtn = () => {
    setMonth(moment(month).subtract(1, "M"));
  };

  const RightMonthBtn = () => {
    setMonth(moment(month).add(1, "M"));
  };

  useEffect(() => {
    setLoading(false);
  }, []);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <React.Fragment>
      <div className="wrap" ref={componentRef}>
        <button
          type="button"
          className="bg-gray-500 border border-gray-500 p-2 mb-4 printHIdeBtn"
          onClick={handlePrint}
          style={{
            position: "absolute",
            top: "130px",
            right: "25px",
            backgroundColor: "#5b5d5f",
            color: "#fff",
            borderRadius: "8px",
          }}
        >
          {" "}
          Print{" "}
        </button>
        {!loading ? (
          <div
            className="divsWrap"
            style={{ padding: "36px 147px 114px 168px" }}
          >
            <div className="m-0 p-0 w-100 row">
              <div className="m-0 p-0 col">
                <div className="m-0 p-0 w-100 row d-flex flex-column">
                  <div className="m-0 p-0 col">
                    <p className="m-0 fs-6" style={{ color: "#2A2D30" }}>
                      {idx.patientName}
                    </p>
                  </div>
                  <div
                    className="m-0 p-0 col d-flex"
                    style={{ alignItems: "baseline" }}
                  >
                    <p
                      style={{
                        fontSize: "10px",
                        color: "#2A2D30",
                        margin: "0px 9px 0px 0px",
                      }}
                    >
                      DOB:
                    </p>
                    <p
                      className="m-0"
                      style={{ fontSize: "15px", color: "#2A2D30" }}
                    >
                      {idx.patientBirth}
                    </p>
                  </div>
                </div>
              </div>
              <div className="m-0 p-0 col">
                <div className="m-0 p-0 w-100 row d-flex flex-column">
                  <div
                    className="m-0 p-0 col d-flex"
                    style={{ alignItems: "baseline" }}
                  >
                    <p
                      style={{
                        fontSize: "10px",
                        color: "#2A2D30",
                        margin: "0px 9px 0px 0px",
                      }}
                    >
                      MRN :
                    </p>
                    <p
                      className="m-0 fs-6"
                      style={{ fontSize: "15px", color: "#2A2D30" }}
                    ></p>
                  </div>
                  <div
                    className="m-0 p-0 col d-flex"
                    style={{ alignItems: "baseline" }}
                  >
                    <p
                      style={{
                        fontSize: "10px",
                        color: "#2A2D30",
                        margin: "0px 9px 0px 0px",
                      }}
                    >
                      장치 :
                    </p>
                    <p
                      className="m-0"
                      style={{ fontSize: "15px", color: "#2A2D30" }}
                    >
                      AGMS T1x
                    </p>
                  </div>
                </div>
              </div>
              <div className="m-0 p-0 col"></div>
            </div>
            <div className="m-0 p-0 w-100 row">
              <div
                className="m-0 p-0 col fs-2 fw-bold"
                style={{ color: "#2A2D30" }}
              >
                혈당 패턴 정보
              </div>
            </div>
            <div
              className="p-0 w-100 row"
              style={{ margin: "0px 0px 17px 0px" }}
            >
              <div className="m-0 p-0 col" style={{ color: "#2A2D30" }}>
                {moment(charts?.startDate).format("YYYY년MM월DD일")} ~{" "}
                {moment(charts?.endDate).format("YYYY년MM월DD일")} (
                {agps?.dateRange}일)
              </div>
            </div>
            <div className="m-0 p-0 w-100 row justify-content-between">
              <div className="m-0 col-12">
                {/* <div
                  className="fs-6 fw-bold"
                  style={{
                    color: "#fff",
                    backgroundColor: "#000000",
                    padding: "4px 8px",
                  }}
                >
                  범위 내 시간
                </div> */}
                <div
                  className="d-flex align-items-center"
                  style={{ marginTop: "33px" }}
                >
                  <div className="w-100">
                    <Row>
                      <Col>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Left className="monthIcons" onClick={LeftMonthBtn} />
                          <div className="bloodDatesRowDiv">
                            <span className="monthTopDate">
                              {month.format("YYYY년 MM월")}
                            </span>
                          </div>
                          <Right
                            className="monthIcons"
                            onClick={RightMonthBtn}
                          />
                        </div>
                        <Card className="bloodSugarCards">
                          <Scheduler
                            month={month.format("YYYY-MM-DD")}
                            monthData={appoint}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="divsWrap"
            style={{
              padding: "36px 147px",
              position: "absolute",
              left: 0,
              top: 0,
              width: "100vw",
              height: "100vh",
              zIndex: 10001,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={Loading}
                alt="loading..."
                style={{
                  width: "50px",
                  height: "50px",
                  animation: ".75s linear infinite spinner-border",
                }}
              />
            </div>
          </div>
        )}
      </div>
      <PrintScreenPagecopy
        setCharts={setCharts}
        charts={charts}
        setAgps={setAgps}
        agps={agps}
        setLoading={setLoading}
        appoCheck={appoCheck}
        setAppoint={setAppoint}
        appoint={appoint}
      />
    </React.Fragment>
  );
};
export default withRouter(MonthlySummary);
