/**
*
* @Authmiddlewre.js
* @brief    Auth 미들웨어
* @details  다중로그인 체크, 세션 시간 경과 체크
* @Copyright- Copyright(c) UXN Ltd., 2022 All rights reserved.
*
*/

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Route, useHistory, Redirect } from "react-router-dom";
import { Col, Row } from "reactstrap";
import moment from "moment";
import { loginGet, requestGet } from "../../apis/request";
import secureLocalStorage from "react-secure-storage";
import { height } from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const token = secureLocalStorage.getItem("authUser");
  const autoLogin = secureLocalStorage.getItem("autoLogin");
  const uuId = secureLocalStorage.getItem("uuid");
  const [diffEndTime, setDiffEndTime] = useState(null);
  const [authTime, setAuthTime] = useState(null);
  const history = useHistory();

  const SESSION_OUT_TIME = 30;
  const INTERVAL_MSEC = 60000

  // /**
  // *
  // * @date     2023.02.02
  // * @brief    브라우저 닫기/새로고침 이벤트 콜백
  // * @details  브라우저 닫기.새로고침 시 팝업 창이 발생 함 - 닫을 때 해야할 일 정의 가능
  // *           단, 새로 고침할때도 팝업창이 뜬다....어떻게 처리하지??? 고민 중...
  // */
  // const preventClose = (e: BeforeUnloadEvent) => {
  //   e.preventDefault();
  //   e.returnValue = ""; // for chrome. deprectaed.

  //   // alert("브라우저를 닫습니다.");

  //   //로그 아웃 및 storage 삭제 등 종료시 필요한 기능 구현
  
  // };

  // useEffect(() => {
  //   (() => {
  //     window.addEventListener("beforeunload", preventClose);
  //   })();
   
  //   return () => {
  //     window.removeEventListener("beforeunload", preventClose);
  //   };
  // }, []);//[] 화면 렌더링시 호출


  /****************************************************************/

  //아래 코드는 브라우저 뒤로가기 방지 코드임
  //사용 시 브라우저에서 뒤로가기 버튼이 안돤다. 필요할 때 쓰자
  // /**
  // *
  // * @date     2023.02.02
  // * @brief    브라우저 뒤로가기 이벤트 콜백
  // * @details  사용 시 브라우저 뒤로가기가 비활성화 된다.
  // * 
  // */
  // const preventGoBack = () => {
  //   window.history.pushState(null, "", window.location.href);
  // };

  // useEffect(() => {
  //   window.history.pushState(null, "", window.location.href);
  //   window.addEventListener("popstate", preventGoBack);
   
  //   return () => {
  //     window.removeEventListener("popstate", preventGoBack);
  //     window.handleCloseDrawer();
  //   };
  // }, []);//[] 화면 렌더링시 호출

  /****************************************************************/

  /**
  *
  * @date     2023.02.01
  * @brief    로그인 시작 시간 얻어오기
  * @details  세션 종료 시간(30분) 체크를 위해 로그인 시작 시간 얻어오기
  * @param    [] - 빈 배열 일 경우 렌더링(최초 접속, 로그인 후 화면 갱신 등) 시 useEffect 실행 됨
  * @return   
  *
  */
  // useEffect(() => {
  //   setAuthTime(secureLocalStorage.getItem("startTime"));
  // }, []);//[]일 경우 렌더링(최초 접속, 로그인 후 화면 갱신 등) 시 useEffect 실행 됨

  /**
  *
  * @date     2023.02.02
  * @brief    로그인 시작 시간 변경 시 호출되어 로그인 유지시간 체크 시작
  * @details  시작하기 변경 시 로그인 유지시간(30분) 체크용 함수 시작(getDataUri - 타이머)
  * @param    authTime - 로그인 시 변경되는 시간
  * @return   
  *
  */
  //  useEffect(() => {
  //   if (authTime) {
  //     let diffTime = moment().diff(authTime, "minute"); //현재 시간-로그인 갱신 시간(분)
  //     let endTime = SESSION_OUT_TIME - diffTime;        //30분과의 시간과 차이

  //     //화면이 갱신되어 authTime이 변경되면 타이머 다시 시작
  //     if (endTime >= 0) //30분이 경과 되었는가? 
  //     {    
  //       // getDataUri(5000);//for Test
  //       getDataUri(endTime * INTERVAL_MSEC);            //세션 아웃 여부 체크 함수 -> 30분 후 동작
  //     }
  //     else //로그인되지 않았으나 로컬에 시간정보가 남아 있는 경우
  //     {
  //       //정상적이라면 로그인 후 이쪽으로 들어오지 않는다.
  //       //화면을 처음 띄울때 오류에 의해 secureLocalStorage에 시간 정보가 남아 있으면 이쪽으로 떨어진다.
  //       //로그아웃 시 삭제되지 않은 버그가 있었으나 예외처리 함.
  //       //하지만 다시 들어 올 가능성은 있음 - 추척 관찰 예정 - 2023/02/02, ykw

  //       // getDataUri(1000); //시간 정보 이상 시 1초 후 바로 동작

  //       //아래는 예외처리 코드

  //       //1. 팝업 창을 띄우지 않고 바로 타이머 초기화로 변경 - 2022/02/01, ykw
  //       //타이머 초기화 시 authTime 갱신되어 useEffect -> getDataUri 순으로 실행 됨
  //       secureLocalStorage.setItem("startTime", moment().format("YYYY-MM-DD HH:mm"));
  //       setAuthTime(moment().format("YYYY-MM-DD HH:mm"));//useEffect가 다시 실행되어 getDataUri 호출 됨

  //       //2. 시간 이상일 경우 팝업창 없이 로그아웃 처리 시도 - ykw
  //       // if (autoLogin) {
  //       //   let autoObj = JSON.parse(autoLogin);
  //       //   autoObj.auto = false;
  //       //   secureLocalStorage.setItem("autoLogin", JSON.stringify(autoObj));
  //       // }

  //       // requestGet(`/logout`)
  //       // .then(result => console.log(result))
  //       // .catch(err => console.log(err));

  //       // console.log(">>>>> Storage delete after logout")

  //       // secureLocalStorage.removeItem("authUser");
  //       // secureLocalStorage.removeItem("refreshtoken");
  //       // secureLocalStorage.removeItem("decoded");
  //       // secureLocalStorage.removeItem("recoil-persist");
  //       // secureLocalStorage.removeItem("startTime");
  //       // secureLocalStorage.removeItem("uuid");

  //       // history.push("/login");
  //     }
  //   }
  // }, [authTime]);//authTime이 갱신되면(로그인 또는 로그인 연장 시) useEffect 실행 됨

  /**
  *
  * @date     2023.02.02
  * @brief    로그인 유지시간(30분) 경과 여부 체크
  * @detail   로그인 유지 시간 경과시 로그인 유지여부를 묻는 팝업 창 표시
              로그인 연장여부 묻기 후 확인 시 30분 연장, 아닐 시 로그아웃 후 로그인창 이동
              로그인 만료 시 로그아웃 url 호출 추가(await 대신 then 방식) - ykw
  * @param    diffEndTime - setTimeout 함수로 해당 시간 이후 실행됨(스케쥴러처럼 동작, 기본 로그인 후 30분)
  * @return   
  *
  */
  // const getDataUri = (diffEndTime) => {
  //   //기존 타이머 초기화
  //   try {
  //     clearTimeout(window.document.timer);
  //   } catch (error) {
  //     console.log(error);
  //   }

  //   //새로운 타이머(setTimeout - 일정 시간(30분) 후 실행) 시작
  //   window.document.timer = setTimeout( () => {
  //     if (
  //       window.confirm("로그인 유지시간이 만료 되었습니다.\n연장하시겠습니까?")
  //     ) 
  //     {
  //       alert("로그인 연장되었습니다.");
  //       console.log(">>>>> 로그인 유지만료 -> 연장 선택")

  //       secureLocalStorage.setItem("startTime", moment().format("YYYY-MM-DD HH:mm"));
  //       setAuthTime(moment().format("YYYY-MM-DD HH:mm"));
  //       return () => {
  //         clearTimeout(window.document.timer);
  //       };
  //     } 
  //     else 
  //     {
  //       alert("로그인이 만료되었습니다.");
  //       console.log(">>>>> 로그인 유지만료 -> 로그아웃 선택")

  //       if (autoLogin) {
  //         let autoObj = JSON.parse(autoLogin);
  //         autoObj.auto = false;
  //         secureLocalStorage.setItem("autoLogin", JSON.stringify(autoObj));
  //       }

  //       //세션 만료시 Logout 추가 - logout해야 토큰이 삭제된다.
  //       //await 방식 -> async 함수에서만 사용 가능
  //       // try {
  //       //   await requestGet(`/logout`);

  //       //   //await 대체 - requestGet이 실행된 후 다음 코드 실행 
  //       //   requestGet(`/logout`)
  //       //   .then(result => console.log(result));

  //       // }
  //       // catch (error){
  //       //   console.log(error);
  //       // }

  //       //requestGet이 async 함수기 때문에 await로 호출 필요, 하지만 지금 호출하는 함수가 async가 아니다.
  //       //then 방식
  //       // requestGet(`/logout`);//그냥 호출 -> 완료를 기다리지 않음

  //       //await 대체 - requestGet이 실행된 후 다음 코드 실행 
  //       requestGet(`/logout`)
  //       .then(result => console.log(result))
  //       .catch(err => console.log(err));

  //       console.log(">>>>> Storage delete after logout")

  //       secureLocalStorage.removeItem("authUser");
  //       secureLocalStorage.removeItem("refreshtoken");
  //       secureLocalStorage.removeItem("decoded");
  //       secureLocalStorage.removeItem("recoil-persist");
  //       secureLocalStorage.removeItem("startTime");
  //       secureLocalStorage.removeItem("uuid");

  //       history.push("/login");
  //       return () => {
  //         clearTimeout(window.document.timer);
  //       };
  //     }
  //   }, diffEndTime);//타이머 발생 시간(기본 30분)
  //   return () => {
  //     clearTimeout(window.document.timer);
  //   };
  // };


  /**
  *
  * @date     2023.02.01
  * @brief    접속중인 곳 이외에 다중 로그인 체크 함수
  * @details  setInterval 함수로 60초에 1번씩 실행 됨
  * @param    currentWebLoginCount - 다른곳 로그인 여부 확인(서버에서 받아 옴)
  * @return   
  *
  */
  // const loginCheck = async () => {
  //   let refreshtoken = secureLocalStorage.getItem("refreshtoken");
  //   const params = new URLSearchParams();
  //   params.append("device", "web");

  //   //로그인 상태 체크(서버에서 토큰 및 IP체크)
  //   let res1 = await loginGet(
  //     `/api/v1/users/check?${params}`,
  //     refreshtoken,
  //     uuId
  //   );

  //   if (res1.currentWebLoginCount > 0) {//다른곳에 로그인 되었다면?
  //     clearInterval(window.document.checktimer);
  //     alert(
  //       "다른 곳에서 로그인되어 로그아웃 합니다.\n본인이 아닐경우 비밀번호 찾기를 통해 비밀번호를 변경 후 로그인 해 주세요."
  //     );

  //     //다른 IP에서 로그인 된 경우 로그아웃 되고 로그인 페이지 이동(서버에서 다른곳 로그인관련 메일 발송 함)
  //     try 
  //     {
  //       await requestGet(`/logout`);

  //       secureLocalStorage.removeItem("authUser");
  //       secureLocalStorage.removeItem("refreshtoken");
  //       secureLocalStorage.removeItem("decoded");
  //       secureLocalStorage.removeItem("recoil-persist");
  //       secureLocalStorage.removeItem("startTime");
  //       secureLocalStorage.removeItem("uuid");

  //       history.push("/login");

  //       return () => {
  //         clearInterval(window.document.checktimer);
  //       };
  //     } 
  //     catch (error) 
  //     {

  //       secureLocalStorage.removeItem("authUser");
  //       secureLocalStorage.removeItem("refreshtoken");
  //       secureLocalStorage.removeItem("decoded");
  //       secureLocalStorage.removeItem("recoil-persist");
  //       secureLocalStorage.removeItem("startTime");
  //       secureLocalStorage.removeItem("uuid");
        
  //       history.push("/login");

  //       return () => {
  //         clearInterval(window.document.checktimer);
  //       };
  //     }
  //   }
  // };

  /**
  *
  * @date     2023.02.01
  * @brief    loginCheck()를 위한 setInterval 실행
  * @details  setInterval로 60초마다 loginCheck 함수를 통헤 다른곳에서 로그인 되었는지 확인
  * @param    token - 로그인 시 갱신되는 token
  * @return   
  *
  */
  // useEffect(() => {
  //   if (token) {
  //     try {
  //       clearInterval(window.document.checktimer);  //타이머 종료(변수 값 갱신을 위해 기존타이머 종료)
  //     } catch (error) {
  //       console.log(error);
  //     }
  //     window.document.checktimer = setInterval(() => {  //60초 마다 반복 시작
  //       loginCheck(); //60초 후 실행 될 함수
  //     }, INTERVAL_MSEC);
  //   }
  // }, [token]);  //로그인 시 token이 갱신되면 useEffect 실행 됨

  return (
    <Route
      {...rest}
      render={(props) => {
        // if (autoLogin === null) {l
        //   return (
        //     <Redirect
        //       to={{ pathname: "/login", state: { from: props.location } }}
        //     />
        //   );
        // }
        return (
          <div >
            <Row className="m-0 p-0 w-100" style={{height:'100%'}}>
              <Row className="m-0 p-0 w-100">
                <Col className="p-0 m-0 w-100">
                  <Layout />
                </Col>
              </Row>
              <Row style={{}}>
                <Col>
                  <Component {...props} />
                </Col>
              </Row>
            </Row>
          </div>
        );
      }}
    />
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;
