import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';


const StackedBarChart = (props) => {
  const options = {
    colors : ['#E77B2B', '#EFA92C','#7DBC7B', '#D8565B', '#93212B'],
    chart: {
        type: 'bar',
        height: 350,
        width:'100%',
        stacked: true,
        stackType: '100%',
        toolbar: {
            show: false,
        },
    },
    plotOptions: {
        bar: {
            horizontal: true,
        },
    },
    stroke: {
        width: 1,
        colors: ['#fff']
    },
    title: {
        text: ''
    },
    xaxis: {
        categories:['']
    },
    
    
    fill: {
        opacity: 1
    },
    legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
    }
  };

  const series = [
    {
        name: '매우높음(>250)',
        data: [props.stackedGraphData.countVeryHighPercent]
    }, 
    {
        name: '높음(>180)',
        data: [props.stackedGraphData.countHighPercent]
    }, 
    {
        name: '목표 범위(>70)',
        data: [props.stackedGraphData.countTargetPercent]
    }, 
    {
        name: '낮음(>50)',
        data: [props.stackedGraphData.countLowPercent]
    }, 
    {
        name: '매우낮음(<50)',
        data: [props.stackedGraphData.countVeryLowPercent]
    }
  ];

  return (
    <div style={{width:'100%' , height:'100%'}}>
        <ReactApexChart options={options} series={series} type="bar" height={'80%'} width={'100%'}/>
    </div>
    
  );
};

export default StackedBarChart;
