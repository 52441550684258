import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import "./header.scss";
import { useHistory, withRouter } from "react-router-dom";
import LogoImg from "../../assets/uxnicon/157.png";
import EmailImg from "../../assets/uxnicon/shape.png";
import ListImg from "../../assets/uxnicon/icon-ionic-md-list-box.png";
import ReWindImg from "../../assets/uxnicon/icon-material-history.png";
import EmailColorImg from "../../assets/uxnicon/shapecolor.png";
import ListColorImg from "../../assets/uxnicon/icon-ionic-md-list-box-color.png";
import ReWindColorImg from "../../assets/uxnicon/icon-material-history-color.png";
import NoticeMenu from "./NoticeMenu";
const Sidebar = () => {
  const [productEl, setProductEl] = useState(null);
  const [orderEl, setOrderEl] = useState(null);
  const [infoEl, setInfoEl] = useState(null);
  const history = useHistory();

  const handleProductClick = (event) => {
    setProductEl(event.currentTarget);
  };

  const handleProductClose = () => {
    setProductEl(null);
  };

  const handleOrderClick = (event) => {
    setOrderEl(event.currentTarget);
  };

  const handleOrderClose = () => {
    setOrderEl(null);
  };

  const handleInfoClick = (event) => {
    setInfoEl(event.currentTarget);
  };

  const handleInfoClose = () => {
    setInfoEl(null);
  };




  return (
    <React.Fragment>
      <Row className="shopHeaderWrap w-100 m-0" style={{ height: "96px"}}>
        <Col className="h-100 w-100 d-none d-lg-block">
          <Row className="h-100 justify-content-center">
            <Col
              xs="1"
              xl="3"
              className="h-100 d-flex justify-content-center"
              style={{ padding: "17px 0px" }}
            >
              <img
                src={LogoImg}
                style={{ height: "100%", cursor: "pointer" }}
                onClick={() => history.push("/patient/list")}
              />
            </Col>
            <Col xs="9" xl="6" className="h-100">
              <Row className="h-100 justify-content-evenly">
                <Col
                  xs="auto"
                  className=" d-flex align-items-center justify-content-start h-100 m-0 "
                  style={{ cursor: "pointer" }}
                  onMouseEnter={handleOrderClick}
                  onMouseLeave={handleOrderClose}
                  onClick={() => history.push("/patient/list")}
                >
                  <img
                    src={
                      orderEl || window.location.pathname === "/patient/list"
                        ? ListColorImg
                        : ListImg
                    }
                    style={{ width: "20px", marginRight: "15.11px" }}
                  />
                  <span
                    style={
                      orderEl || window.location.pathname === "/patient/list"
                        ? {
                            fontSize: "21px",
                            color: "#56C79C",
                            fontFamily: "",
                          }
                        : {
                            fontSize: "21px",
                            color: "#202020",
                            fontFamily: "",
                          }
                    }
                  >
                    수검자 리스트
                  </span>
                </Col>
                <Col
                  xs="auto"
                  className=" d-flex align-items-center justify-content-start h-100 m-0 "
                  style={{ cursor: "pointer" }}
                  onMouseEnter={handleInfoClick}
                  onMouseLeave={handleInfoClose}
                  onClick={() => history.push("/patient/accept/list")}
                >
                  <img
                    src={
                      infoEl ||
                      window.location.pathname.includes("/patient/accept/list")
                        ? ReWindColorImg
                        : ReWindImg
                    }
                    style={{ width: "23.33px", marginRight: "15.12px" }}
                  />
                  <span
                    style={
                      infoEl ||
                      window.location.pathname.includes("/patient/accept/list")
                        ? {
                            fontSize: "21px",
                            color: "#56C79C",
                            fontFamily: "",
                          }
                        : {
                            fontSize: "21px",
                            color: "#202020",
                            fontFamily: "",
                          }
                    }
                  >
                    승인대기 리스트
                  </span>
                </Col>
                <Col
                  xs="auto"
                  className=" d-flex align-items-center justify-content-start h-100 m-0 "
                  style={{ cursor: "pointer" }}
                  onMouseEnter={handleProductClick}
                  onMouseLeave={handleProductClose}
                  onClick={() => history.push("/hospital-profile")}
                >
                  <img
                    src={
                      productEl ||
                      window.location.pathname === "/hospital-profile"
                        ? EmailColorImg
                        : EmailImg
                    }
                    style={{ width: "20px", marginRight: "15.19px" }}
                  />
                  <span
                    style={
                      productEl ||
                      window.location.pathname === "/hospital-profile"
                        ? {
                            fontSize: "21px",
                            color: "#56C79C",
                            fontFamily: "",
                          }
                        : {
                            fontSize: "21px",
                            color: "#202020",
                            fontFamily: "",
                          }
                    }
                  >
                    계정 설정
                  </span>
                </Col>

              </Row>
            </Col>
            <Col
              xs="2"
              xl="3"
              className="h-100 d-flex align-items-center justify-content-center"
            >
              <div>
                {/* <img src={BellImg} style={{ width: "27px" }} /> */}
                <NoticeMenu />
              </div>
              <div style={{ margin: "0px 0px 0px 30px" }}>
                {/* <img
                  src={UserImg}
                  style={{ width: "43px", height: "42px", cursor: "pointer" }}
                  onClick={() => onLogOut()}
                /> */}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <Row style={{ height: "96px" }}></Row> */}
    </React.Fragment>
  );
};
export default withRouter(Sidebar);

