import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import SplineAreaChart from "../Charts/SplineAreaChart";
import "./bloodsugarrecord.scss";
const BloodSugarRecord = () => {
  const history = useHistory();
  return (
    <React.Fragment>
      <div className="bloodSugarWrap">
        <Row className="bloodSugarRow">
          <Col md="6">
            <div className="bloodSugarDown">혈당 데이터 다운로드</div>
            <Card className="bloodCard">
              <div className="newBloodCardWrap">
                <div className="d-flex justify-content-center">
                  <span className="bloodName">새 혈당 데이터</span>
                </div>
                <div className="bloodSubTitle">
                  아래에서 혈당 내역을 보거나 혈당 보고서를 클릭하여 지금 보기
                  또는 PDF로 인쇄/저장하기가 가능한 사용자 지정보고서를
                  만드십시오.
                </div>
              </div>
            </Card>
            <div className="bloodBtnWrap">
              <button
                className="bloodBtn"
                onClick={() => history.push("/agp-report")}
              >
                혈당 보고서
              </button>
            </div>
          </Col>
        </Row>
        <Row className="bloodSugarDataRow m-0">
          <div className="bloodSugarDataRowDiv">
            <span className="dateToDate">2021년 0월 0일 - 2021년 0월 0일</span>
          </div>
          <Card className="bloodCard">
            <div className="bloodDataCard">
              <div className="bloodSubTitle">일련번호가 이곳에 출력됩니다.</div>
              <div className="bloodDataCardRow">
                <div className="bloodDataCardDiv">
                  <span className="bloodDataCardDivNum">000</span>
                  <span className="bloodDataCardDivSpan">단위</span>
                  <span className="bloodDataCardDivBlood">혈당</span>
                </div>
                <div className="bloodDataCardDiv">
                  <span className="bloodDataCardDivNum">000</span>
                  <span className="bloodDataCardDivSpan">단위</span>
                  <span className="bloodDataCardDivBlood">혈당</span>
                </div>
                <div className="bloodDataCardDivLast">
                  <span className="bloodDataCardDivNum">000</span>
                  <span className="bloodDataCardDivSpan">단위</span>
                  <span className="bloodDataCardDivBlood">혈당</span>
                </div>
                <div className="recordChart">
                  <SplineAreaChart />
                </div>
                <div />
              </div>
            </div>
          </Card>
        </Row>
        <Row className="bloodSugarDataRow m-0">
          <div className="bloodSugarDataRowDiv">
            <span className="dateToDate">2021년 0월 0일 - 2021년 0월 0일</span>
          </div>
          <Card className="bloodCard">
            <div className="bloodSubDataCard">
              <div className="bloodSubTitle">데이터가 없습니다.</div>
            </div>
          </Card>
        </Row>
        <Row className="bloodSugarDataRow m-0">
          <div className="bloodSugarDataRowDiv">
            <span className="dateToDate">2021년 0월 0일 - 2021년 0월 0일</span>
          </div>
          <Card className="bloodCard">
            <div className="bloodSubDataCard">
              <div className="bloodSubTitle">데이터가 없습니다.</div>
            </div>
          </Card>
        </Row>
        <Row className="bloodSugarDataRow m-0">
          <div className="bloodSugarDataRowDiv">
            <span className="dateToDate">2021년 0월 0일 - 2021년 0월 0일</span>
          </div>
          <Card className="bloodCard">
            <div className="bloodSubDataCard">
              <div className="bloodSubTitle">데이터가 없습니다.</div>
            </div>
          </Card>
        </Row>
        <Row className="bloodSugarDataRow m-0">
          <div className="bloodSugarDataRowDiv">
            <span className="dateToDate">2021년 0월 0일 - 2021년 0월 0일</span>
          </div>
          <Card className="bloodCard">
            <div className="bloodSubDataCard">
              <div className="bloodSubTitle">데이터가 없습니다.</div>
            </div>
          </Card>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default withRouter(BloodSugarRecord);
