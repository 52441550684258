import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, Col, Container, Input, Row, Form, Button } from "reactstrap";
import { requestGet, agmsClientRequestGet } from "../../apis/request";
import "./lineupmodal.scss";

const FindIdModal = ({ findId, setFindId }) => {
  const [email, setEmail] = useState("");

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const closeXModal = (e) => {
    setFindId(!findId);
  };

  const onFindPw = async (e) => {
    e.preventDefault();
    if (email !== "") {
      try {
        const params = new URLSearchParams();
        params.append("email", email);
        await agmsClientRequestGet(`/api/mail/link?${params}`);
        alert(
          "비밀번호 초기화 이메일이 발송되었습니다.\n링크를 클릭하여 비밀번호를 초기화 해주세요."
        );
        setFindId(!findId);
      } catch (error) {
        if (error.response?.status === 404) {
          alert("사용자를 찾을 수 없습니다.");
        } else {
          alert("메일을 전송 할 수 없습니다.");
        }
      }
    } else {
      return alert("이메일을 입력해 주세요.");
    }
  };

  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center">
          <Col>
            <Form
              style={{ display: "flex", justifyContent: "center" }}
              onSubmit={onFindPw}
            >
              <div className="modalOverlay" />
              <Card className="modalCard">
                <Row className="w-100" style={{ margin: "0px 0px 15px 0px" }}>
                  <Col
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    비밀번호를 잊어버리셨습니까?
                  </Col>
                </Row>
                <Row className="w-100" style={{ margin: "0px 0px 22px 0px" }}>
                  <Col style={{ fontSize: "17px", fontWeight: "300" }}>
                    귀하의 계정과 연결된 이메일 주소를 입력하면 비밀번호를
                    재설정할 수 있는 링크가 포함된 이메일을 보내드립니다.
                    <p>
                    비밀번호 변경 가능 시간은 30분이 지나면 변경이 불가 합니다. 
                    </p>
                  </Col>
                </Row>
                <Row className="w-100" style={{ margin: "0px 0px 20px 0px" }}>
                  <Col className="d-flex align-items-end">
                    <Input
                      type="email"
                      placeholder="이메일 주소"
                      className="findPwInput"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="w-100 m-0">
                  <Col className="d-flex justify-content-between">
                    <Button
                      style={{
                        border: "none",
                        backgroundColor: "#A7A7A7",
                        borderRadius: "8px",
                        padding: "15px 40px",
                        color: "#000",
                      }}
                      onClick={() => closeXModal()}
                    >
                      취소
                    </Button>
                    <Button
                      style={{
                        border: "none",
                        backgroundColor: "#3EA6FF",
                        borderRadius: "8px",
                        padding: "15px 40px",
                        color: "#fff",
                        fontWeight: "600",
                      }}
                      type="submit"
                    >
                      링크 보내기
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default withRouter(FindIdModal);
