import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
//import Cards from "../../components/cards/Cards";
import "./main.scss";
import UserImg from "../../assets/uxnicon/user2.png";
import { requestGet, agmsClientRequestGet, agmsClientRequestPatch, agmsClientRequestPost } from "../../apis/request";
import Loading from "../../assets/uxnicon/loadingIcon.png";
import { useSetRecoilState } from "recoil";
import { user } from "../../states/recoilState";

import BorderColorIcon from '@mui/icons-material/BorderColor';
import IconButton from '@mui/material/IconButton';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import secureLocalStorage from "react-secure-storage";


const Dashboard = () => {

  const history = useHistory();
  const [staffInfo , setStaffInfo] = useState({
    email: "",
    hospitalName: "",
    id: 0,
    name: "",
    phoneNumber: "",
    role: ""
  });


  //화면의 크기를 실시간으로 탐지하고 반영하기
  const [width, setWidth] = useState(window.innerWidth);
  const [openWithdraw, setOpenWithdraw] = React.useState(false);
  const [openEditPassword, setOpenEditPassword] = useState(false);
  
  const [password, setPassword] = useState('');
  
  const [isWithdrawal, setIsWithdrawal]= useState(false);
  const [widthStyle, setWidthStyle] = useState({
    marginTop:'100px',  
    marginLeft:'auto', 
    marginRight:'auto', 
    height:'auto', 
    border:'1px solid #151515', 
    width:'1000px',
    display:'flex', 
    flexDirection:'column', 
    alignItems:'center'
  });


  //API Call 메소드
  const callPasswordCheckApi = (input) => { //토큰 있어야 함
    let inputPassword = null;
    if(input === 'withdraw'){
      inputPassword = password;
    }
    else if(input === 'change_password'){
      inputPassword = current_password;
    }
    console.log('inputPassword');
    console.log(inputPassword);
    let passwordCheckResult = agmsClientRequestPatch(`/api/staff/password-check/${secureLocalStorage.getItem('email')}`, {
      password: inputPassword,
      //user_id: secureLocalStorage.getItem('email')
    });

    passwordCheckResult.then((data) => {
      //비밀번호 변경
      if(input === 'change_password'){
        if(data.message === 'True'){
          callApi_password_update();
        }
        else 
        {
          alert('기존 비밀번호가 일치하지 않습니다.');
        }
      }
      //회원 탈퇴
      else if(input === 'withdraw')
      {
        if(data.message === 'True'){
          setIsWithdrawal(true);
        }
        else
        {
          setIsWithdrawal(false);
        }
      } 
    });
  }

  const callWithDrawelApi = async () => {
    let withdrawerResult = null;
    if(secureLocalStorage.getItem('role') === 'ADMIN_STAFF'){
      withdrawerResult = await agmsClientRequestPost(`/api/staff/admin/delete/${secureLocalStorage.getItem('email')}`);
      alert('삭제되었습니다!');
      history.push('/login');
    }
    else{
      withdrawerResult =  agmsClientRequestPost(`/api/staff/delete/${secureLocalStorage.getItem('email')}`);
      alert('삭제되었습니다!');
      history.push('/login');
    }
    console.log(withdrawerResult);
  }


  //UI 이벤트 handle 메소드
  const handleWithdrawConfirmPassword = () => {
    callPasswordCheckApi('withdraw');
  }

  const handleClickOpen = (input) => {
    if(input === 'withdraw'){
      setPassword(null);
      setOpenWithdraw(true);
    }
    else{
      setPassword(null);
      set_current_password(null);
      set_new_password(null);
      set_new_password_confirm(null);
      setOpenEditPassword(true);
    }
  };

  const handleWithdrawClose = () => {
    setOpenWithdraw(false);
  }

  const onChangePasswordSignUp = (e) => {
    setPassword(e.target.value);
  }


  //비밀번호 변경
  const [current_password, set_current_password] = useState('');
  const [new_password, set_new_password] = useState('');
  const [new_password_confirm, set_new_password_confirm] = useState('');

  const handleEditPasswordClose = () => {
    setOpenEditPassword(false);
  };

  const onChangeCurrentPassword = (e) =>{
    set_current_password(e.target.value);
  }

  const onChangeNewPassword = (e) =>{
    set_new_password(e.target.value);
  }

  const onChangeNewPasswordConfirm = (e) =>{
    set_new_password_confirm(e.target.value);
  }
  
  const callApi_password_update = () => {
    let password_update_result = agmsClientRequestPatch(`/api/staff/password-update/${secureLocalStorage.getItem('email')}`, {
      password: new_password
    });
    password_update_result.then((data) => {
      if(data.message === 'password update succeed'){
        alert('성공적으로 변경 완료 되었습니다.')
        setOpenEditPassword(false);
      }
      else{
        alert('변경에 실패했습니다.')
        setOpenEditPassword(false);
      }
      console.log(data.message);
    });
    console.log(password_update_result);
  }

  const handleEditPasswordConfirm = (e) => {
    //비밀번호 check 일치하지 않았을 때
    if(new_password !== new_password_confirm){
      alert('새 비밀번호 확인이 일치하지 않습니다.');
    }else{
      callPasswordCheckApi('change_password');
    }
  }

  const callApi = async (input) => {
    try{
      let staffResult = await agmsClientRequestGet(`/api/staff/${input}`);
      setStaffInfo(staffResult);
    }
    catch(error){
      console.log('Dashboard callApi 에러 : ' + error);
    }
    
  };

  //useEffect들
  useEffect(() => {
    // const handleResize = () => setWidth(window.innerWidth);
    // window.addEventListener('resize', handleResize);
    // return () => window.removeEventListener('resize', handleResize);
    callApi(secureLocalStorage.getItem('email'));
  }, []);

  useEffect(()=>{
    if(isWithdrawal === true){
      callWithDrawelApi();
    }
  }, [isWithdrawal]);

  useEffect(()=>{
    if(width > 1000){
      setWidthStyle({
        marginTop:'100px',  
        marginLeft:'auto', 
        marginRight:'auto', 
        height:'auto', 
        border:'1px solid #151515', 
        width:'1000px',
        display:'flex', 
        flexDirection:'column', 
        alignItems:'center'
      });
    }else{
      setWidthStyle({
        marginTop:'100px',  
        marginLeft:'auto', 
        marginRight:'auto', 
        height:'auto', 
        border:'1px solid #151515', 
        width:'80%',
        display:'flex', 
        flexDirection:'column', 
        alignItems:'center'
      });
    }
  },[width]);

  let maskingFunc = {
    checkNull : function (str){
        if(typeof str === "undefined" || str === null || str === ""){
            return true;
        }
        else{
            return false;
        }
    },
    /*
    ※ 이메일 마스킹
    ex1) 원본 데이터 : abcdefg12345@naver.com
        변경 데이터 : ab**********@naver.com
    ex2) 원본 데이터 : abcdefg12345@naver.com
        변경 데이터 : ab**********@nav******
    */
    email : function(str){
        let originStr = str;
        let emailStr = originStr.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
        let strLength;
        
        if(this.checkNull(originStr) === true || this.checkNull(emailStr) === true){
            return originStr;
        }else{
            strLength = emailStr.toString().split('@')[0].length - 3;
            
            // ex1) abcdefg12345@naver.com => ab**********@naver.com
            // return originStr.toString().replace(new RegExp('.(?=.{0,' + strLength + '}@)', 'g'), '*');

            // ex2) abcdefg12345@naver.com => ab**********@nav******
            return originStr.toString().replace(new RegExp('.(?=.{0,' + strLength + '}@)', 'g'), '*').replace(/.{6}$/, "******");
        }
    },
    /* 
    ※ 휴대폰 번호 마스킹
    ex1) 원본 데이터 : 01012345678, 변경 데이터 : 010****5678
    ex2) 원본 데이터 : 010-1234-5678, 변경 데이터 : 010-****-5678
    ex3) 원본 데이터 : 0111234567, 변경 데이터 : 011***4567
    ex4) 원본 데이터 : 011-123-4567, 변경 데이터 : 011-***-4567
    */
    phone : function(str){
        let originStr = str;
        let phoneStr;
        let maskingStr;
        
        if(this.checkNull(originStr) === true){
            return originStr;
        }
        
        if (originStr.toString().split('-').length !== 3)
        { // 1) -가 없는 경우
            phoneStr = originStr.length < 11 ? originStr.match(/\d{10}/gi) : originStr.match(/\d{11}/gi);
            if(this.checkNull(phoneStr) === true){
                return originStr;
            }
            
            if(originStr.length < 11)
            { // 1.1) 0110000000
                maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{3})(\d{4})/gi,'$1***$3'));
            }
            else
            { // 1.2) 01000000000
                maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{4})(\d{4})/gi,'$1****$3'));
            }
        }else
        { // 2) -가 있는 경우
            phoneStr = originStr.match(/\d{2,3}-\d{3,4}-\d{4}/gi);
            if(this.checkNull(phoneStr) === true){
                return originStr;
            }
            
            if(/-[0-9]{3}-/.test(phoneStr))
            { // 2.1) 00-000-0000
                maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/-[0-9]{3}-/g, "-***-"));
            } else if(/-[0-9]{4}-/.test(phoneStr))
            { // 2.2) 00-0000-0000
                maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/-[0-9]{4}-/g, "-****-"));
            }
        }
        
        return maskingStr;
    },
    /*
    ※ 주민등록 번호 마스킹 (Resident Registration Number, RRN Masking)
    ex1) 원본 데이터 : 990101-1234567, 변경 데이터 : 990101-1******
    ex2) 원본 데이터 : 9901011234567, 변경 데이터 : 9901011******
    */
    rrn : function(str){
        let originStr = str;
        let rrnStr;
        let maskingStr;
        let strLength;
        
        if(this.checkNull(originStr) === true){
            return originStr;
        }
        
        rrnStr = originStr.match(/(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))-[1-4]{1}[0-9]{6}\b/gi);
        if(this.checkNull(rrnStr) === false){
            strLength = rrnStr.toString().split('-').length;
            maskingStr = originStr.toString().replace(rrnStr,rrnStr.toString().replace(/(-?)([1-4]{1})([0-9]{6})\b/gi,"$1$2******"));
        }else {
            rrnStr = originStr.match(/\d{13}/gi);
            if(this.checkNull(rrnStr) === false){
                strLength = rrnStr.toString().split('-').length;
                maskingStr = originStr.toString().replace(rrnStr,rrnStr.toString().replace(/([0-9]{6})$/gi,"******"));
            }else{
                return originStr;
            }
        }
        return maskingStr;
    },
    /*
    ※ 이름 마스킹
    ex1) 원본 데이터 : 갓댐희, 변경 데이터 : 갓댐*
    ex2) 원본 데이터 : 하늘에수, 변경 데이터 : 하늘**
    ex3) 원본 데이터 : 갓댐, 변경 데이터 : 갓*
    */
    name : function(str){
        let originStr = str;
        let maskingStr;
        let strLength;
        
        if(this.checkNull(originStr) === true){
            return originStr;
        }
        
        strLength = originStr.length;
        
        if(strLength < 3){
            maskingStr = originStr.replace(/(?<=.{1})./gi, "*");
        }else {
            maskingStr = originStr.replace(/(?<=.{4})./gi, "*");
        }
        
        return maskingStr;
    },

    code : function(str){
      let originStr = str;
      let maskingStr;
      let strLength;
      
      if(this.checkNull(originStr) === true){
          return originStr;
      }
      
      strLength = originStr.length;
      
      if(strLength < 3){
          maskingStr = originStr.replace(/(?<=.{1})./gi, "*");
      }else {
          maskingStr = originStr.replace(/(?<=.{0})./gi, "*");
      }
      
      return maskingStr;
    }
  };

  useEffect(() => {
    //callApi();
  }, []);


  return (

    <div style={{height:'85vh'}}>
      <Card style={widthStyle}>
        <div style={{marginTop:'50px', display:'flex', flexDirection:'column', alignItems:'center'}}>
          <div style={{fontWeight:'bold', fontSize:'30px'}}>
            <span>내 정보</span>
            <span>
              <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  //onClick={toggleDrawer(true)}
                  color="inherit"
                  >
                  <BorderColorIcon />
              </IconButton>
            </span>
          </div>
          
          <div style={{display:'flex', width:'100%'}}>
            <div style={{flex:'1', display:'flex', flexDirection:'column', alignItems:'end'}}>
              <div style={{flex:'1', fontWeight:'bold', marginTop:'50px', fontSize:'15px'}}>이름 : </div>
              <div style={{flex:'1',fontWeight:'bold', marginTop:'30px', fontSize:'15px'}}>병원 이름 : </div>
              <div style={{flex:'1',fontWeight:'bold', marginTop:'30px', fontSize:'15px'}}>전화번호 : </div>
              <div style={{flex:'1',fontWeight:'bold', marginTop:'30px', fontSize:'15px'}}>이메일 : </div>
            </div>

            <div style={{flex:'2.2'}}>
              <div style={{flex:'1', marginTop:'50px', fontSize:'15px'}}>&nbsp;&nbsp;&nbsp;{staffInfo.name}</div>
              <div style={{flex:'1', marginTop:'30px', fontSize:'15px'}}>&nbsp;&nbsp;&nbsp;{staffInfo.hospitalName}</div>
              <div style={{flex:'1', marginTop:'30px', fontSize:'15px'}}>&nbsp;&nbsp;&nbsp;{staffInfo.phoneNumber}</div>
              <div style={{flex:'1', marginTop:'30px', fontSize:'15px'}}>&nbsp;&nbsp;&nbsp;{staffInfo.email}</div>
            </div>
          </div>
          
        
          <div style={{display:'flex', width:'130%'}}>
            <Button variant="outlined" onClick={()=> handleClickOpen('change_password')} style={{marginTop:'40px', marginBottom:'50px', width:'130px'}} >비밀번호 변경</Button>
            <Button variant="outlined" onClick={()=> handleClickOpen('withdraw')} style={{marginTop:'40px', marginBottom:'50px', marginLeft:'auto', width:'130px'}}>회원 탈퇴</Button>
          </div> 
        </div>
        
      </Card>

      {/* 회원탈퇴 */}
      <Dialog
        open={openWithdraw}
        fullWidth
        onClose={handleWithdrawClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"비밀번호 입력해주세요"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

            <TextField 
                      fullWidth 
                      type="password"
                      // label="비밀번호" 
                      placeholder='비밀번호'
                      value={password}
                      onChange={onChangePasswordSignUp}
                      />

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWithdrawClose}>취소</Button>
          <Button onClick={() => handleWithdrawConfirmPassword()} autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>

      {/* 비밀번호 변경 */}
      <Dialog
        open={openEditPassword}
        fullWidth
        onClose={handleEditPasswordClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"비밀번호 변경"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          
          <div style={{height:"15px"}}></div>
          <TextField 
              fullWidth 
              type="password"
              label="기존 비밀번호" 
              value={current_password}
              onChange={onChangeCurrentPassword}/>

          <div style={{height:"15px"}}></div>
          <div>
            영문자, 숫자, 특수문자를 포함한 8~35자 비밀번호를 입력해주세요.
          </div>
          <div>
            허용된 특수문자 :!@%$%^&*()+=._-
          </div>
          <div style={{height:"15px"}}></div>
          <TextField 
              fullWidth 
              type="password"
              label="새 비밀번호" 
              value={new_password}
              onChange={onChangeNewPassword}/>

          <div style={{height:"15px"}}></div>          
          <TextField 
              fullWidth 
              type="password"
              label="새 비밀번호 확인"
              value={new_password_confirm} 
              onChange={onChangeNewPasswordConfirm}/>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditPasswordClose}>취소</Button>
          <Button onClick={() => handleEditPasswordConfirm()} autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    
  );
};

export default withRouter(Dashboard);
