import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/theme.scss";
import "./App.css";
import { Switch, BrowserRouter as Router } from "react-router-dom";

// Import Routes all
import { userRoutes, authRoutes, bloodSugarRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";
import Detailmiddleware from "./routes/middleware/Detailmiddleware";
import Sidebar from "./pages/Sidebar/Sidebar";
import LoginSidebar from "./pages/Sidebar/LoginSidebar";
import {agmsClientRequestGet} from "./apis/request";

import ResponsiveAppBar from "./pages/Sidebar/ResponsiveAppBar";
import secureLocalStorage from "react-secure-storage";

import { useEffect } from "react";

const App = (props) => {

  // const callLogoutApi = async () => {
  //   let result = await agmsClientRequestGet(
  //     `/api/logout`
  //   );
  //   secureLocalStorage.removeItem("refreshtoken");
  //   secureLocalStorage.removeItem("accesstoken");
  //   secureLocalStorage.removeItem('authUser');
  //   secureLocalStorage.removeItem('autoLogin');
  // };

  return ( 
    <React.Fragment >
        <Router>
          <Switch>
            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={LoginSidebar}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {userRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={ResponsiveAppBar}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}

            {bloodSugarRoutes.map((route, idx) => (
              <Detailmiddleware
                path={route.path}
                layout={Sidebar}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
          </Switch>
        </Router>
    </React.Fragment>
  );
};

export default App;
