import React, { forwardRef, useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Card, Col, Row, Table } from "reactstrap";
import {
  VictoryArea,
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryTheme,
  VictoryBar,
} from "victory";
import "./bloodsugar.scss";
import VicBarChart from "../Charts/VicBarChart";
import VicLineCharts from "../Charts/VicLineCharts";
import ApexCharts from "../Charts/ApexCharts";
import { requestGet } from "../../apis/request";
import Loading from "../../assets/uxnicon/loadingIcon.png";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  percentData,
  userID,
  // agpData,
  appointments,
  printData,
} from "../../states/recoilState";
import SplineAreaDailyChart from "../Charts/SplineAreaDailyChart";
import SplineAreaChart from "../Charts/SplineAreaChart";
import { ReactComponent as Raindrops } from "../../assets/uicons-regular-rounded/svg/fi-rr-raindrops.svg";
import { ReactComponent as Apple } from "../../assets/uicons-regular-rounded/svg/fi-rr-apple.svg";
import { ReactComponent as Syringe } from "../../assets/uicons-regular-rounded/svg/fi-rr-syringe.svg";
import { ReactComponent as DarkSyringe } from "../../assets/uicons-solid-rounded/svg/fi-sr-syringe.svg";
import Scheduler from "../Scheduler/Scheduler";
import { ReactComponent as Left } from "../../assets/uicons-regular-rounded/svg/fi-rr-angle-small-left.svg";
import { ReactComponent as Right } from "../../assets/uicons-regular-rounded/svg/fi-rr-angle-small-right.svg";
import moment from "moment";
import SplineAreaDailyChart1 from "../Charts/SplineAreaDailyChart1";

const PrintScreenPagecopy = (props, ref) => {
  const [settingData, setSettingData] = useState({});
  const idx = useRecoilValue(userID);
  const chartdata = useRecoilValue(percentData);
  // const agpData = useRecoilValue(agpData);
  const [month, setMonth] = useState(moment().subtract(1, "d"));
  const [imgData, setImgData] = useState({});
  const [printImgData, setPrintImgData] = useState({});
  const [nextChart, setNextChart] = useState("");
  const appointdata = useRecoilValue(appointments);
  const printChartData = useRecoilValue(printData);
  const setCharts = props.setCharts;
  const charts = props.charts;
  const setAgps = props.setAgps;
  const agps = props.agps;
  const setLoading = props.setLoading;
  const appoCheck = props.appoCheck;
  const setAppoint = props.setAppoint;
  const appoint = props.appoint;
  let graphdata = agps?.dayGraphDataList;
  const history = useHistory();
  const LeftMonthBtn = () => {
    setMonth(moment(month).subtract(1, "M"));
  };

  const RightMonthBtn = () => {
    setMonth(moment(month).add(1, "M"));
  };

  const callApi = async () => {
    try {
      let arr = [];
      let arr1 = {};
      // let arr2 = [];
      const params = new URLSearchParams();
      params.append("endDate", chartdata?.endDate);
      params.append("startDate", chartdata?.startDate);
      let res = await requestGet(`/api/v1/report/detail/${idx.id}?${params}`);
      setAgps(res);
      for (let i = 0; i < res.dayGraphDataList.length; i++) {
        let arr3 = [];
        let arr4 = [];
        let day = res.dayGraphDataList[i].targetDate;
        let day1 = moment(res.dayGraphDataList[i].targetDate).format(
          "MM월 DD일"
        );
        arr.push({
          startDate: moment(day).format("YYYY-MM-DD 00:00"),
          endDate: moment(day).format("YYYY-MM-DD 24:00"),
          title: `평균 혈당 : ${res.dayGraphDataList[i].summary.averageGlucose}`,
        });
        for (let j = 0; j < res.dayGraphDataList[i].dataList.length; j++) {
          arr3.push({
            x: res.dayGraphDataList[i].timeStringList[j],
            y: res.dayGraphDataList[i].dataList[j],
          });
          arr4.push({
            x: res.dayGraphDataList[i].timeStringList[j],
            y: 70,
            y0: 180,
          });
        }
        arr1[day1] = arr3;
      }
      if (appoCheck) {
        setAppoint(arr);
      }
      // setPrintChartData({ printPdfData: arr1 });
      // setPrintChartCenterData({ printPdfCenterData: arr2 });
      setLoading(false);
    } catch (error) {
      console.log(error);
      alert("데이터가 없습니다.");
      setLoading(false);
      // history.goback();
    }
  };
  useEffect(() => {
    forApi();
  }, []);

  const forApi = async () => {
    setLoading(true);
    try {
      let arr = [];
      const params = new URLSearchParams();
      params.append("page", 1);
      let res = await requestGet(`/api/v1/report/list/${idx.id}?${params}`);
      let dateArr1 = [];
      let dateArr2 = [];
      let dateArr3 = [];
      let dates = [];
      let index = chartdata.idx;
      for (let j = 0; j < res.list[index].profileGraphData?.date.length; j++) {
        dateArr1.push({
          x: res.list[index].profileGraphData.date[j],
          y: Number(res.list[index].profileGraphData["5%"][j]),
          y0: Number(res.list[index].profileGraphData["95%"][j]),
        });
        dateArr2.push({
          x: res.list[index].profileGraphData.date[j],
          y: Number(res.list[index].profileGraphData["25%"][j]),
          y0: Number(res.list[index].profileGraphData["75%"][j]),
        });
        dateArr3.push({
          x: res.list[index].profileGraphData.date[j],
          y: Number(res.list[index].profileGraphData["50%"][j]),
        });
        dates.push(res.list[index].profileGraphData.date[j]);
      }
      setCharts({
        startDate: res.list[index].startDate,
        endDate: res.list[index].endDate,
        serialNumber: res.list[index].deviceID,
        nomalBlood: res.list[index].averageGlucose,
        dataDate: res.list[index].sensorActivePercent,
        bloodDown: res.list[index].lowerGlucoseCount,
        deviceID: res.list[index].deviceID,
        chartData1: dateArr1,
        chartData2: dateArr2,
        chartData3: dateArr3,
        dates: dates,
      });
      callApi();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (agps) {
      for (let i = 0; i < agps?.dayGraphDataList?.length; i++) {
        if (agps.dayGraphDataList[i].targetDate) {
          return setNextChart(
            moment(agps?.dayGraphDataList[i]?.targetDate).format("MM월 DD일")
          );
        }
      }
    }
  }, [agps]);

  return (
    <React.Fragment>
      <div className="printTotalwrap" id="refid">
        <div className="divsWrap">
          <div className="m-0 p-0 w-100 row">
            <div className="m-0 p-0 col">
              <div className="m-0 p-0 w-100 row d-flex flex-column">
                <div className="m-0 p-0 col">
                  <p className="m-0 fs-6" style={{ color: "#2A2D30" }}>
                    {idx.patientName}
                  </p>
                </div>
                <div
                  className="m-0 p-0 col d-flex"
                  style={{ alignItems: "baseline" }}
                >
                  <p
                    style={{
                      fontSize: "10px",
                      color: "#2A2D30",
                      margin: "0px 9px 0px 0px",
                    }}
                  >
                    DOB:
                  </p>
                  <p
                    className="m-0"
                    style={{ fontSize: "15px", color: "#2A2D30" }}
                  >
                    {idx.patientBirth}
                  </p>
                </div>
              </div>
            </div>
            <div className="m-0 p-0 col">
              <div className="m-0 p-0 w-100 row d-flex flex-column">
                <div
                  className="m-0 p-0 col d-flex"
                  style={{ alignItems: "baseline" }}
                >
                  <p
                    style={{
                      fontSize: "10px",
                      color: "#2A2D30",
                      margin: "0px 9px 0px 0px",
                    }}
                  >
                    MRN :
                  </p>
                  <p
                    className="m-0 fs-6"
                    style={{ fontSize: "15px", color: "#2A2D30" }}
                  ></p>
                </div>
                <div
                  className="m-0 p-0 col d-flex"
                  style={{ alignItems: "baseline" }}
                >
                  <p
                    style={{
                      fontSize: "10px",
                      color: "#2A2D30",
                      margin: "0px 9px 0px 0px",
                    }}
                  >
                    장치 :
                  </p>
                  <p
                    className="m-0"
                    style={{ fontSize: "15px", color: "#2A2D30" }}
                  >
                    AGMS T1x
                  </p>
                </div>
              </div>
            </div>
            <div className="m-0 p-0 col"></div>
          </div>
          <div className="m-0 p-0 w-100 row">
            <div
              className="m-0 p-0 col fs-2 fw-bold"
              style={{ color: "#2A2D30" }}
            >
              AGP 보고서
            </div>
          </div>
          <div className="p-0 w-100 row" style={{ margin: "0px 0px 17px 0px" }}>
            <div className="m-0 p-0 col" style={{ color: "#2A2D30" }}>
              {moment(charts?.startDate).format("YYYY년MM월DD일")} ~{" "}
              {moment(charts?.endDate).format("YYYY년MM월DD일")} (
              {agps?.dateRange}일)
            </div>
          </div>
          <div className="m-0 p-0 w-100 row justify-content-between">
            <div className="m-0 col-6">
              <div
                className="fs-6 fw-bold"
                style={{
                  color: "#fff",
                  backgroundColor: "#000000",
                  padding: "4px 8px",
                }}
              >
                혈당 통계 및 목표값
              </div>
              <div
                className="d-flex justify-content-between"
                style={{
                  padding: "13px 0px",
                  borderBottom: "1px solid #707070",
                }}
              >
                <div>
                  <p className="m-0">
                    {moment(charts?.startDate).format("YYYY년MM월DD일")} ~{" "}
                    {moment(charts?.endDate).format("YYYY년MM월DD일")}
                  </p>
                  <p className="m-0">% 시간 CGM이 활성 상태임</p>
                </div>
                <div>
                  <p className="m-0 text-end">{agps?.dateRange}일</p>
                  <p className="m-0 text-end">
                    {agps?.sensorActivePercentString}%
                  </p>
                </div>
              </div>
              <div
                className="d-flex justify-content-between"
                style={{
                  padding: "13px 0px",
                  borderBottom: "1px solid #707070",
                }}
              >
                <div>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    다음에 대한 범위 및 목표값
                  </p>
                </div>
                <div>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    1형 또는 2형 당뇨병
                  </p>
                </div>
              </div>
              <div
                className="d-flex justify-content-between"
                style={{
                  padding: "13px 0px",
                  borderBottom: "1px solid #707070",
                }}
              >
                <div>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    혈당 범위
                  </p>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    ‪목표 범위 70-180 mg/dL
                  </p>
                </div>
                <div>
                  <p
                    className="m-0 text-end"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    목표값
                  </p>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    {`${agps?.targetGlucosePercent}%보다 큼 ‏‪(${agps?.targetGlucoseMinute}분)`}
                  </p>
                </div>
              </div>
              <div
                className="d-flex justify-content-between"
                style={{
                  padding: "13px 0px",
                  borderBottom: "1px solid #707070",
                }}
              >
                <div>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    70 mg/dL 미만
                  </p>
                </div>
                <div>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    {`${agps?.lowGlucosePercent}%보다 큼 ‏‪(${agps?.lowGlucoseMinute}분)`}
                    ‏‪
                  </p>
                </div>
              </div>
              <div
                className="d-flex justify-content-between"
                style={{
                  padding: "13px 0px",
                  borderBottom: "1px solid #707070",
                }}
              >
                <div>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    54 mg/dL 미만
                  </p>
                </div>
                <div>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    {`${agps?.veryLowGlucosePercent}%보다 큼 ‏‪(${agps?.veryLowGlucoseMinute}분)`}
                  </p>
                </div>
              </div>
              <div
                className="d-flex justify-content-between"
                style={{
                  padding: "13px 0px",
                  borderBottom: "1px solid #707070",
                }}
              >
                <div>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    180 mg/dL 초과
                  </p>
                </div>
                <div>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    {`${agps?.highGlucosePercent}%보다 큼 ‏‪(${agps?.highGlucoseMinute}분)`}
                  </p>
                </div>
              </div>
              <div
                className="d-flex justify-content-between"
                style={{
                  padding: "13px 0px",
                  borderBottom: "1px solid #707070",
                }}
              >
                <div>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    250 mg/dL 초과
                  </p>
                </div>
                <div>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    {`${agps?.veryHighGlucosePercent}%보다 큼 ‏‪(${agps?.veryHighGlucoseMinute}분)`}
                  </p>
                </div>
              </div>
              <div
                className="d-flex justify-content-between"
                style={{
                  padding: "13px 0px",
                  borderBottom: "1px solid #707070",
                }}
              >
                <div>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    다음에 대한 범위 및 목표값
                  </p>
                </div>
                <div>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    1형 또는 2형 당뇨병
                  </p>
                </div>
              </div>
              <div
                className=""
                style={{
                  padding: "13px 0px",
                  borderBottom: "1px solid #707070",
                }}
              >
                <div
                  className="w-100 d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    평균 혈당
                  </p>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    {agps?.averageGlucose}mg/dL
                  </p>
                </div>
                <div
                  className="w-100 d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    혈당 관리 표시기(GMI)
                  </p>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    {agps?.gmi}% 또는 {agps?.gmiMol}mmol/mol
                  </p>
                </div>
                <div
                  className="w-100 d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    변동계수%
                  </p>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    {agps?.glucoseCV}%
                  </p>
                </div>
                <div
                  className="w-100 d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    변동계수 백분율(%CV)로 정의
                  </p>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  ></p>
                </div>
              </div>
            </div>
            <div className="m-0 col-5">
              <div
                className="fs-6 fw-bold"
                style={{
                  color: "#fff",
                  backgroundColor: "#000000",
                  padding: "4px 8px",
                }}
              >
                혈당 통계 및 목표값
              </div>
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "33px" }}
              >
                <VicBarChart
                  data={{
                    low: agps?.lowGlucosePercent,
                    verylow: agps?.veryLowGlucosePercent,
                    target: agps?.targetGlucosePercent,
                    high: agps?.highGlucosePercent,
                    veryhigh: agps?.veryHighGlucosePercent,
                  }}
                />
                <div className="w-100">
                  <div className="d-flex justify-content-between">
                    <div>매우 높음</div>
                    <div>{`${agps?.veryHighGlucosePercent}%`}</div>
                  </div>
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      borderBottom: "1px dashed #707070",
                      marginBottom: "10px",
                    }}
                  >
                    <div>{`‪>250 ‬mg/dL`}</div>
                    <div>{`‏‪(${agps?.veryHighGlucoseMinute}분)`}</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>높음</div>
                    <div>{`${agps?.highGlucosePercent}%`}</div>
                  </div>
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      borderBottom: "1px dashed #707070",
                      marginBottom: "33px",
                    }}
                  >
                    <div>181 - 250 ‬mg/dL</div>
                    <div>{`‏‪(${agps?.highGlucoseMinute}분)`}</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>목표 범위</div>
                    <div>{`${agps?.targetGlucosePercent}%`}</div>
                  </div>
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      borderBottom: "1px dashed #707070",
                      marginBottom: "49px",
                    }}
                  >
                    <div>70 - 180 ‬mg/dL</div>
                    <div>{`‏‪(${agps?.targetGlucoseMinute}분)`}‬</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>낮음</div>
                    <div>{`${agps?.lowGlucosePercent}%`}</div>
                  </div>
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      borderBottom: "1px dashed #707070",
                      marginBottom: "10px",
                    }}
                  >
                    <div>54 - 69 ‬mg/dL</div>
                    <div>{`‏‪(${agps?.lowGlucoseMinute}분)`}‬</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>매우 낮음</div>
                    <div>{`${agps?.veryLowGlucosePercent}%`}</div>
                  </div>
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      borderBottom: "1px dashed #707070",
                    }}
                  >
                    <div>{`‪<54 ‬mg/dL`}</div>
                    <div>{`‏‪(${agps?.veryLowGlucoseMinute}분)`}‬</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="m-0 p-0 w-100 row flex-column">
            <div
              className="m-0 p-0 w-100 col"
              style={{ color: "#fff", backgroundColor: "#000000" }}
            >
              외래 혈당 프로필 (AGP)
            </div>
            <div
              className="m-0 p-0 w-100 col"
              style={{
                color: "#2A2D30",
                fontSize: "13px",
              }}
            >
              AGP는 보고서 기간의 혈당 값을 요약한 것으로, 중앙값(50%) 및 기타
              백분위수가 하루에 발생한 것처럼 함께 표시됩니다.
            </div>
            <div>
              <VictoryChart width={1000}>
                <VictoryArea
                  style={{
                    data: {
                      fill: "#56C79C",
                      fillOpacity: 0.4,
                      strokeWidth: 0,
                    },
                  }}
                  interpolation="natural"
                  data={charts?.chartData1}
                />
                <VictoryArea
                  style={{
                    data: {
                      fill: "#56C79C",
                      fillOpacity: 0.7,
                      strokeWidth: 0,
                    },
                  }}
                  interpolation="natural"
                  data={charts?.chartData2}
                />
                <VictoryLine
                  style={{
                    data: { stroke: "#56C79C" },
                    parent: { border: "1px solid #56C79C" },
                  }}
                  interpolation="natural"
                  data={charts?.chartData3}
                />
                <VictoryAxis dependentAxis tickCount={10} crossAxis={false} />
                <VictoryAxis
                  tickValues={charts?.dates}
                  tickCount={5}
                  crossAxis={false}
                />
              </VictoryChart>
            </div>
          </div>
          <div className="m-0 p-0 w-100 row flex-column">
            <div
              className="m-0 p-0 w-100 col"
              style={{ color: "#fff", backgroundColor: "#000000" }}
            >
              일일 혈당 프로필
            </div>
            <div
              className="p-0 w-100 col"
              style={{
                color: "#2A2D30",
                fontSize: "13px",
                margin: "0px 0px 17px 0px",
              }}
            >
              각 일일 프로필은 자정에서 자정까지의 기간을 나타내며 왼쪽 상단
              모서리에 날짜가 표시됩니다.
            </div>
            <div className="m-0 p-0 w-100 col">
              <div className="m-0 p-0 row row-cols-7">
                {agps?.dayGraphDataList?.map((el, i) => {
                  return (
                    <div
                      className="m-0 p-0"
                      style={{ minWidth: `${100 / 7}%` }}
                    >
                      <div className="m-0 p-0 w-100 row row-cols-1">
                        <div
                          className="p-0 w-100 col text-center"
                          style={{
                            margin: "0px 0px 17px 0px",
                          }}
                        >
                          <span style={{ fontSize: "18px" }}>
                            {moment(el.targetDate).format("MM월 DD일")}
                          </span>
                        </div>
                        <div
                          className="p-0 w-100 col"
                          style={{
                            height: "150px",
                            border: "1px solid #707070",
                            borderRadius: "5px",
                            // margin: "0px 0px 37px 0px",
                          }}
                        >
                          <SplineAreaDailyChart1
                            data={el.dataList}
                            time={el.timeStringList}
                            day={moment(el.targetDate).format("MM월 DD일")}
                            setImgData={setImgData}
                            imgData={imgData}
                            nextChart={nextChart}
                            nextDay={moment(
                              agps?.dayGraphDataList[i + 1]?.targetDate
                            ).format("MM월 DD일")}
                            setNextChart={setNextChart}
                            printImgData={printImgData}
                            setPrintImgData={setPrintImgData}
                            end={
                              agps?.dayGraphDataList.length !== i + 1
                                ? false
                                : true
                            }
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="divsWrap">
          <div className="m-0 p-0 w-100 row">
            <div
              className="m-0 p-0 col fs-2 fw-bold"
              style={{ color: "#2A2D30" }}
            >
              혈당 패턴 정보
            </div>
          </div>
          <div className="m-0 p-0 w-100 row justify-content-between">
            <div className="m-0 col-5">
              <div
                className="fs-6 fw-bold"
                style={{
                  color: "#fff",
                  backgroundColor: "#000000",
                  padding: "4px 8px",
                }}
              >
                범위 내 시간
              </div>
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "33px" }}
              >
                <VicBarChart
                  data={{
                    low: agps?.lowGlucosePercent,
                    verylow: agps?.veryLowGlucosePercent,
                    target: agps?.targetGlucosePercent,
                    high: agps?.highGlucosePercent,
                    veryhigh: agps?.veryHighGlucosePercent,
                  }}
                />
                <div className="w-100">
                  <div className="d-flex justify-content-between">
                    <div>매우 높음</div>
                    <div>{`${agps?.veryHighGlucosePercent}%`}</div>
                  </div>
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      borderBottom: "1px dashed #707070",
                      marginBottom: "10px",
                    }}
                  >
                    <div>{`‪>250 ‬mg/dL`}</div>
                    <div>{`‏‪(${agps?.veryHighGlucoseMinute}분)`}</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>높음</div>
                    <div>{`${agps?.highGlucosePercent}%`}</div>
                  </div>
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      borderBottom: "1px dashed #707070",
                      marginBottom: "33px",
                    }}
                  >
                    <div>181 - 250 ‬mg/dL</div>
                    <div>{`‏‪(${agps?.highGlucoseMinute}분)`}</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>목표 범위</div>
                    <div>{`${agps?.targetGlucosePercent}%`}</div>
                  </div>
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      borderBottom: "1px dashed #707070",
                      marginBottom: "49px",
                    }}
                  >
                    <div>70 - 180 ‬mg/dL</div>
                    <div>{`‏‪(${agps?.targetGlucoseMinute}분)`}</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>낮음</div>
                    <div>{`${agps?.lowGlucosePercent}%`}</div>
                  </div>
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      borderBottom: "1px dashed #707070",
                      marginBottom: "10px",
                    }}
                  >
                    <div>54 - 69 ‬mg/dL</div>
                    <div>{`‏‪(${agps?.lowGlucoseMinute}분)`}‬</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>매우 낮음</div>
                    <div>{`${agps?.veryLowGlucosePercent}%`}</div>
                  </div>
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      borderBottom: "1px dashed #707070",
                    }}
                  >
                    <div>{`‪<54 ‬mg/dL`}</div>
                    <div>{`‏‪(${agps?.veryLowGlucoseMinute}분)`}‬</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="m-0 col-6">
              <div
                className="fs-6 fw-bold"
                style={{
                  color: "#fff",
                  backgroundColor: "#000000",
                  padding: "4px 8px",
                }}
              >
                혈당 통계
              </div>
              <div
                className="d-flex justify-content-between"
                style={{
                  padding: "13px 0px",
                  borderBottom: "1px solid #707070",
                }}
              >
                <div>
                  <p className="m-0">평균 혈당</p>
                  <p className="m-0">{agps?.averageGlucose}mg/dL</p>
                </div>
              </div>
              <div
                className="d-flex justify-content-between"
                style={{
                  padding: "13px 0px",
                  borderBottom: "1px solid #707070",
                }}
              >
                <div>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    혈당 관리 지표(GMI)
                  </p>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    평균 CGM 혈당 수준을 기준으로 한 대략적인 AIC 수준.
                  </p>
                  <p
                    className="m-0"
                    style={{ fontSize: "14px", color: "#2A2D30" }}
                  >
                    {agps?.gmi}% | {agps?.gmiMol}mmol/mol
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="m-0 p-0 w-100 row flex-column">
            <div
              className="m-0 p-0 w-100 col"
              style={{ color: "#fff", backgroundColor: "#000000" }}
            >
              임상의를 위한 고려사항
            </div>
            <div
              className="m-0 p-0 w-100 col"
              style={{
                color: "#2A2D30",
                fontSize: "13px",
                marginBottom: "42px",
              }}
            >
              가장 중요한 패턴 :
              <span>{agps?.importantPattern?.kindKeyword}</span>
            </div>
            <div style={{ height: "320px" }}>
              <div className="row m-0 p-0">
                <div className="col-6">
                  <div>약물</div>
                </div>
                <div className="col-6">
                  <div>생활방식</div>
                </div>
              </div>
            </div>
          </div>
          <div className="m-0 p-0 w-100 row flex-column">
            <div
              className="m-0 p-0 w-100 col"
              style={{ color: "#fff", backgroundColor: "#000000" }}
            >
              혈당패턴
            </div>
            <div className="m-0 p-0 w-100 col">
              <div style={{ height: "320px" }}>
                <VictoryChart width={1000}>
                  <VictoryArea
                    style={{
                      data: {
                        fill: "#56C79C",
                        fillOpacity: 0.4,
                        strokeWidth: 0,
                      },
                    }}
                    interpolation="natural"
                    data={charts?.chartData1}
                  />
                  <VictoryArea
                    style={{
                      data: {
                        fill: "#56C79C",
                        fillOpacity: 0.7,
                        strokeWidth: 0,
                      },
                    }}
                    interpolation="natural"
                    data={charts?.chartData2}
                  />
                  <VictoryLine
                    style={{
                      data: { stroke: "#56C79C" },
                      parent: { border: "1px solid #56C79C" },
                    }}
                    interpolation="natural"
                    data={charts?.chartData3}
                  />
                  <VictoryAxis dependentAxis tickCount={10} crossAxis={false} />
                  <VictoryAxis
                    tickValues={charts?.dates}
                    tickCount={8}
                    crossAxis={false}
                  />
                </VictoryChart>
              </div>
            </div>
          </div>
        </div>
        <div className="divsWrap">
          <div className="bloodDatesRowDiv">
            <span className="bloodDates">
              {moment(charts?.startDate).format("YYYY년MM월DD일")} ~{" "}
              {moment(charts?.endDate).format("YYYY년MM월DD일")}
            </span>
          </div>
          {agps?.dayGraphDataList?.map((el, i) => {
            return (
              <div className="w-100 m-0 p-0 dailyLogfirstRow">
                <div className="w-100 m-0 p-0">
                  <div className="bloodDatesRowDiv">
                    <span className="bloodDates">
                      {moment(el.targetDate).format("YYYY년 MM월 DD일")}
                    </span>
                  </div>
                  <div
                    className="w-100 m-0 p-0 bloodSugarCards"
                    style={{ height: "353px", padding: "50px 41px 19px 7px" }}
                  >
                    <div style={{ width: "100%", height: "100%" }}>
                      <SplineAreaChart
                        data={el.dataList}
                        time={el.timeStringList}
                        day={el.targetDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="w-100 m-0 p-0 divsWrap">
          <div className="bloodDatesRowDiv" style={{ marginBottom: "18px" }}>
            <span className="bloodDates">2021년 0월 0일 - 2021년 0월 0일</span>
          </div>
          <div className="w-100 m-0 p-0">
            <Row className="w-100 m-0 p-0">
              <div
                className="snapTitleLineWrap"
                style={{ marginBottom: "21px" }}
              >
                <Raindrops className="icons" />
                <span className="iconsTitle" style={{ width: "70px" }}>
                  혈당
                </span>
                <div className="titleLine" />
              </div>
              <Col className="w-100 m-0 p-0">
                <Card
                  className="w-100 m-0 p-0 bloodSugarSubTotalCard"
                  style={{
                    height: "651px",
                    padding: "37.5px 148px 59px 26px",
                  }}
                >
                  <div style={{ width: "100%", height: "100%" }}>
                    <SplineAreaChart />
                  </div>
                </Card>
              </Col>
            </Row>
            <Row>
              <div
                className="snapTitleLineWrap"
                style={{ marginBottom: "21px" }}
              >
                <Apple className="icons" />
                <span className="iconsTitle" style={{ width: "120px" }}>
                  탄수화물
                </span>
                <div className="titleLine" />
              </div>
              <Col>
                <Card
                  className="bloodSugarSubTotalCard"
                  style={{
                    height: "535px",
                    padding: "37.5px 148px 59px 26px",
                  }}
                >
                  <div style={{ height: "100%" }}>
                    <SplineAreaChart />
                  </div>
                </Card>
              </Col>
            </Row>
            <Row>
              <div
                className="snapTitleLineWrap"
                style={{ marginBottom: "21px" }}
              >
                <DarkSyringe className="icons" />
                <span className="iconsTitle" style={{ width: "210px" }}>
                  초속효성 인슐린
                </span>
                <div className="titleLine" />
              </div>
              <Col>
                <Card
                  className="bloodSugarSubTotalCard"
                  style={{
                    padding: "40px 80px 59.5px 26px",
                  }}
                >
                  <div style={{ height: "100%" }}>
                    <SplineAreaChart />
                  </div>
                </Card>
              </Col>
            </Row>
            <Row>
              <div
                className="snapTitleLineWrap"
                style={{ marginBottom: "21px" }}
              >
                <Syringe className="icons" />
                <span className="iconsTitle" style={{ width: "180px" }}>
                  지속형 인슐린
                </span>
                <div className="titleLine" />
              </div>
              <Col>
                <Card
                  className="bloodSugarSubTotalCard"
                  style={{
                    padding: "40px 80px 59.5px 26px",
                  }}
                >
                  <div style={{ height: "100%" }}>
                    <SplineAreaChart />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <div className="divsWrap">
          <div>
            <Row>
              <Col>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Left className="monthIcons" onClick={LeftMonthBtn} />
                  <div className="bloodDatesRowDiv">
                    <span className="monthTopDate">
                      {month.format("YYYY년 MM월")}
                    </span>
                  </div>
                  <Right className="monthIcons" onClick={RightMonthBtn} />
                </div>
                <Card className="bloodSugarCards">
                  <Scheduler
                    month={month.format("YYYY-MM-DD")}
                    monthData={appointdata.appointments}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <div className="divsWrap">
          <div className="bloodDatesRowDiv" style={{ marginBottom: "18px" }}>
            <span className="topDate">
              {moment(charts?.startDate).format("YYYY년MM월DD일")} ~{" "}
              {moment(charts?.endDate).format("YYYY년MM월DD일")}
            </span>
          </div>
          <div className="snapTitleLineWrap">
            <Raindrops className="icons" />
            <span className="iconsTitle" style={{ width: "70px" }}>
              혈당
            </span>
            <div className="titleLine" />
          </div>
          <div>
            {agps?.dayGraphDataList?.map((el, i) => {
              return (
                <>
                  <div className="bloodDatesRowDiv d-flex justify-content-center">
                    <span className="snapDate">
                      {moment(el.targetDate).format("YYYY년 MM월 DD일")}
                    </span>
                  </div>
                  <Row className="snapRow">
                    <Col xs="12" xl="6">
                      <Card
                        className="snapCard"
                        style={{
                          height: "353px",
                          padding: "50px 40px 19px 7px",
                        }}
                      >
                        <div style={{ height: "100%" }}>
                          <SplineAreaChart
                            data={el.dataList}
                            time={el.timeStringList}
                            day={el.targetDate}
                          />
                        </div>
                      </Card>
                    </Col>
                    <Col xs="12" xl="6">
                      <Card
                        className="snapCard"
                        style={{ height: "353px", padding: "30px 49px 28px" }}
                      >
                        <Table style={{ height: "100%" }}>
                          <thead className="tableHeader">
                            <tr>
                              <th style={{ textAlign: "center", width: "25%" }}>
                                평균 혈당
                              </th>
                              <th style={{ textAlign: "center", width: "25%" }}>
                                총 탄수화물
                              </th>
                              <th style={{ textAlign: "center", width: "25%" }}>
                                총 인슐린
                              </th>
                              <th style={{ textAlign: "center", width: "25%" }}>
                                저혈당 증상
                              </th>
                            </tr>
                          </thead>
                          <tbody className="tableBorder">
                            <tr>
                              <th scope="row" className="tableBodyRight">
                                {el.summary.averageGlucose}
                              </th>
                              <td className="tableBodyRight">000</td>
                              <td className="tableBodyRight">000</td>
                              <td className="tableBodyLast">
                                {el.summary.lowerGlucoseCount}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card>
                    </Col>
                  </Row>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default forwardRef(PrintScreenPagecopy);
