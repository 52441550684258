import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();


export const selectedExperimentState = atom({
  key:'selectedExperiment',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const seletedPatientIdState = atom({
  key:'seletedPatientId',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const weeklyLastDateState = atom({
  key:'weeklyLastDate',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const isCurrentBloodSugarAtom = atom({
  key:'isCurrentBloodSugar',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const badgeOnOffAtom = atom({
  key:'badgeOnOff',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const useRefStateAtom = atom({
  key:'useRef',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

////////////////////

export const user = atom({
  key: "user",
  default: {
    name: "",
    email: "",
  },
  effects_UNSTABLE: [persistAtom],
});


export const changeAuth = atom({
  key: "changeAuth",
  default: {
    authority: "NO",
  },
  effects_UNSTABLE: [persistAtom],
});

export const userID = atom({
  key: "userID",
  default: {
    id: null,
    patientName: "",
    patientBirth: "",
    patientemail: "",
  },
  effects_UNSTABLE: [persistAtom],
});

export const agpData = atom({
  key: "agpData",
  default: {
    data: null,
  },
  effects_UNSTABLE: [persistAtom],
});

export const appointments = atom({
  key: "appointments",
  default: {
    appointments: [],
  },
  effects_UNSTABLE: [persistAtom],
});

export const dataAverage = atom({
  key: "dataAverage",
  default: {
    average: [],
  },
  effects_UNSTABLE: [persistAtom],
});

export const percentData = atom({
  key: "percentData",
  default: {
    data1: [],
    data2: [],
    data3: [],
    dates: [],
    startDate: "",
    endDate: "",
    deviceID: "",
    idx: "",
  },
  effects_UNSTABLE: [persistAtom],
});

// export const printData = atom({
//   key: "printData",
//   default: {
//     printPdfData: [],
//   },
//   effects_UNSTABLE: [persistAtom],
// });

export const printData = atom({
  key: "printData",
  default: {},
});

export const printCenterData = atom({
  key: "printCenterData",
  default: {
    printPdfCenterData: [],
  },
  effects_UNSTABLE: [persistAtom],
});

export const termAllAgree = atom({
  key: "termAllAgree",
  default: {
    agree: 'false',
  },
  effects_UNSTABLE: [persistAtom],
});
