import React from "react";
import { withRouter } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import BackImg from "../../assets/settings-265131_1920_2021-11-02/settings-265131_1920.png";
import "./profile.scss";
const ConnectApp = () => {
  return (
    <React.Fragment>
      <img src={BackImg} alt="backimg" className="dashboardBackImg" />
      <div className="account-pages">
        <Row className="dashRow m-0">
          <Col md="7">
            <Card className="listAddCard">
              <div className="deviceInWrap">
                <div className="d-flex justify-content-center">
                  <span className="myDeviceName">
                    데이터 공유 Connection 관리
                  </span>
                </div>
                <div className="birthDay d-flex justify-content-center">
                  귀하는 당뇨병 관리 정보를 어떤 앱과도 공유하고 있지 않습니다.
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default withRouter(ConnectApp);
