import React, { useEffect, useState } from "react";
import { VictoryStack, VictoryChart, VictoryBar } from "victory";

const VicBarChart = ({ data }) => {
  const [myDataset, setMyDataset] = useState([]);
  useEffect(() => {
    setMyDataset([
      [{ x: "매우낮음", y: data.verylow }],
      [{ x: "낮음", y: data.low }],
      [{ x: "목표범위", y: data.target }],
      [{ x: "높음", y: data.high }],
      [{ x: "매우높음", y: data.veryhigh }],
    ]);
  }, [data]);
  // This is an example of a function you might use to transform your data to make 100% data
  const transformData = (dataset) => {
    const totals = dataset[0]?.map((data, i) => {
      return dataset.reduce((memo, curr) => {
        return memo + curr[i].y;
      }, 0);
    });
    return dataset?.map((data) => {
      return data?.map((datum, i) => {
        return { x: datum.x, y: (datum.y / totals[i]) * 100 };
      });
    });
  };
  const dataset = transformData(myDataset);
  return (
    <div style={{ width: "100px", height: "100%" }}>
      {/* <VictoryChart height={400} width={400} domainPadding={{ x: 0, y: 0 }}> */}
      <VictoryStack
        colorScale={["#B6202E", "#D71920", "#40AC49", "#FFF100", "#FCB814"]}
        height={1800}
      >
        {dataset?.map((data, i) => {
          return <VictoryBar barWidth={350} data={data} key={i} />;
        })}
      </VictoryStack>
      {/* </VictoryChart> */}
    </div>
  );
};

export default VicBarChart;
