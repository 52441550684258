import React, { useEffect, useState, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import ApexCharts from "apexcharts";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { printData } from "../../states/recoilState";

const SplineAreaDailyChart1 = ({
  data,
  time,
  day,
  setImgData,
  imgData,
  nextChart,
  nextDay,
  setNextChart,
  end,
}) => {
  const [lineData, setLineData] = useState([]);
  const chart = useRef(null);
  // useEffect(() => {
  //   if (nextChart === day) {
  //     getDataUri();
  //   }
  // }, [nextChart]);

  useEffect(() => {
    let arr = [];
    for (let i = 0; i < data?.length; i++) {
      arr.push({
        x: time[i],
        y: data[i],
      });
    }
    setLineData(arr);
  }, [data, time]);

  const series = [
    {
      name: day,
      data: lineData,
    },
  ];

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    yaxis: {
      min: 0,
      max: 360,
      tickAmount: 5,
    },
    annotations: {
      position: "back",
      yaxis: [
        {
          label: {
            text: " ",
          },
          y: 180,
          y2: 70,
          fillColor: "#cccccc",
        },
      ],
    },

    colors: ["#556ee6"],
    xaxis: {
      tickAmount: 3,
    },
    chart: {
      id: day,
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };
  // const getDataUri = () => {
  //   let timer = setTimeout(() => {
  //     ApexCharts.exec(day, "dataURI").then(({ imgURI }) => {
  //       let nowData = { [day]: imgURI };
  //       setImgData({ ...imgData, ...nowData });
  //       if (!end) {
  //         setNextChart(nextDay);
  //       }
  //     });
  //   }, 1000);
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // };

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height="350"
        className="apex-charts"
        dir="ltr"
        height="100%"
        width="100%"
        ref={chart}
      />
    </>
  );
};

export default SplineAreaDailyChart1;
