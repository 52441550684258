import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Route, useHistory, Redirect } from "react-router-dom";
import { Col, Row } from "reactstrap";
import BackBtn from "../../assets/uxnicon/169468.png";
import "./middleware.scss";
import moment from "moment";
//import { loginGet, requestGet } from "../../apis/request";
import secureLocalStorage from "react-secure-storage";
const Detailmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const token = secureLocalStorage.getItem("authUser");
  const autoLogin = secureLocalStorage.getItem("autoLogin");
  const uuId = secureLocalStorage.getItem("uuid");
  const [diffEndTime, setDiffEndTime] = useState(null);
  const [authTime, setAuthTime] = useState(null);
  const history = useHistory();

  const SESSION_OUT_TIME = 30;
  const INTERVAL_MSEC = 60000

  useEffect(() => {
    //setAuthTime(secureLocalStorage.getItem("startTime"));
  }, []);

  useEffect(() => {
    if (authTime) {
      let diffTime = moment().diff(authTime, "minute");
      let endTime = SESSION_OUT_TIME - diffTime;
      
      if (endTime >= 0) 
      {
        setDiffEndTime(endTime * INTERVAL_MSEC);
      } 
      else //로그인되지 않았으나 로컬에 시간정보가 남아 있는 경우
      {
        // getDataUri(1000);//secureLocalStorage 저장 시간 이상 시

        //팝업 창을 띄우지 않고 바로 타이머 초기화로 변경 - ykw
        //로그인 시 체크시간은 다시 초기화 됨
        secureLocalStorage.setItem("startTime", moment().format("YYYY-MM-DD HH:mm"));
        setAuthTime(moment().format("YYYY-MM-DD HH:mm"));
      }
    }
  }, [authTime]);

  //로그인 유지시간(30분) 만료여부 체크
  // const getDataUri = (diffEndTime) => {
  //   try {
  //     clearTimeout(window.document.timer);
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   window.document.timer = setTimeout(() => {
  //     if (
  //       window.confirm("로그인 유지시간이 만료 되었습니다.\n연장하시겠습니까?")
  //     ) 
  //     {
  //       alert("로그인이 연장되었습니다.");
  //       console.log(">>>>> 로그인 유지만료 -> 연장 선택")

  //       secureLocalStorage.setItem("startTime", moment().format("YYYY-MM-DD HH:mm"));
  //       setAuthTime(moment().format("YYYY-MM-DD HH:mm"));
  //       return () => {
  //         clearTimeout(window.document.timer);
  //       };
  //     } 
  //     else 
  //     {
  //       alert("로그인이 만료 되었습니다.");
  //       console.log(">>>>> 로그인 유지만료 -> 로그아웃 선택")

  //       if (autoLogin) {
  //         let autoObj = JSON.parse(autoLogin);
  //         autoObj.auto = false;
  //         secureLocalStorage.setItem("autoLogin", JSON.stringify(autoObj));
  //       }

  //       //세션 만료시 Logout 추가 - logout해야 토큰이 삭제된다.
  //       // requestGet(`/logout`);
  //       requestGet(`/logout`)
  //       .then(result => console.log(result))
  //       .catch(err => console.log(err));

  //       console.log(">>>>> Storage delete after logout")

  //       secureLocalStorage.removeItem("authUser");
  //       secureLocalStorage.removeItem("refreshtoken");
  //       secureLocalStorage.removeItem("decoded");
  //       secureLocalStorage.removeItem("recoil-persist");
  //       secureLocalStorage.removeItem("startTime");
  //       secureLocalStorage.removeItem("uuid");

  //       history.push("/login");
  //       return () => {
  //         clearTimeout(window.document.timer);
  //       };
  //     }
  //   }, diffEndTime);
  //   return () => {
  //     clearTimeout(window.document.timer);
  //   };
  // };

  //다른곳에서 로그인되었는지 체크
  // const loginCheck = async () => {
  //   let refreshtoken = secureLocalStorage.getItem("refreshtoken");
  //   const params = new URLSearchParams();
  //   params.append("device", "web");

  //   let res1 = await loginGet(
  //     `/api/v1/users/check?${params}`,
  //     refreshtoken,
  //     uuId
  //   );

  //   if (res1.currentWebLoginCount > 0) {
  //     alert(
  //       "다른 곳에서 로그인되어 로그아웃 합니다.\n본인이 아닐경우 비밀번호 찾기를 통해 비밀번호를 변경 후 로그인 해 주세요."
  //     );
  //     try 
  //     {
  //       await requestGet(`/logout`);
  //       secureLocalStorage.removeItem("authUser");
  //       secureLocalStorage.removeItem("refreshtoken");
  //       secureLocalStorage.removeItem("decoded");
  //       secureLocalStorage.removeItem("recoil-persist");
  //       secureLocalStorage.removeItem("startTime");
  //       secureLocalStorage.removeItem("uuid");
  //       history.push("/login");
  //       return () => {
  //         clearInterval(window.document.checktimer);
  //       };
  //     } 
  //     catch (error) 
  //     {
  //       secureLocalStorage.removeItem("authUser");
  //       secureLocalStorage.removeItem("refreshtoken");
  //       secureLocalStorage.removeItem("decoded");
  //       secureLocalStorage.removeItem("recoil-persist");
  //       secureLocalStorage.removeItem("startTime");
  //       secureLocalStorage.removeItem("uuid");
  //       history.push("/login");
  //       return () => {
  //         clearInterval(window.document.checktimer);
  //       };
  //     }
  //   }
  // };

  useEffect(() => {
    if (token) {
      try {
        clearInterval(window.document.checktimer);
      } catch (error) {
        console.log(error);
      }
      window.document.checktimer = setInterval(() => {
        //loginCheck();
      }, INTERVAL_MSEC);
    }
  }, [token]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !token) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
        return (
          <Row className="m-0">
            <Row className="m-0 p-0 w-100" id="printLayoutHide">
              <Col className="p-0 m-0 w-100">
                <Layout />
              </Col>
            </Row>
            <Row
              className="m-0 w-100"
              style={{ padding: "15px 24px 10px" }}
              id="printLayoutHideBtn"
            >
              <Col className="p-0 m-0 w-100">
                <img
                  src={BackBtn}
                  alt="backbtn"
                  onClick={() => history.push("/haldang")}
                />
              </Col>
            </Row>
            <Row className="m-0 p-0 w-100">
              <Col xs="2" className="p-0 m-0" id="printSide">
              </Col>
              <Col xs="10" className="p-0 m-0" id="printBody">
                <Component {...props} />
              </Col>
            </Row>
          </Row>
        );
      }}
    />
  );
};

Detailmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Detailmiddleware;
