import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, Col, Container, Row, Form, Button } from "reactstrap";
import { useSetRecoilState } from "recoil";
import { termAllAgree } from "../../states/recoilState";
import "../Authentication/lineupmodal.scss";

const TermAgreement = ({ termAgreement, setTermAgreement }) => {

  const setTermAllAgreeInfo = useSetRecoilState(termAllAgree);

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const closeXModal = (e) => {
    setTermAgreement(!termAgreement);
    setTermAllAgreeInfo({
      agree: false
    });
  };

  const onAllAgreement = async (e) => {
    e.preventDefault();
    if(allCheck === false) {
      alert("전체 동의해야 회원 가입이 가능합니다.");
    }  
    else{
      setTermAllAgreeInfo({ agree: "true" });//이용 약관 동의여부 true
      setTermAgreement(!termAgreement);
    }

  };

  // 약관동의 체크박스 관련 - add 2023-04-26, ykw
  const [allCheck, setAllCheck] = useState(false);
  const [termCheck, setTermCheck] = useState(false);
  const [pInfoCheck, setPInfoCheck] = useState(false);

  const allBtnEvent =()=>{
    if(allCheck === false) {
      setAllCheck(true);
      setTermCheck(true);
      setPInfoCheck(true);
    }else {
      setAllCheck(false);
      setTermCheck(false);
      setPInfoCheck(false);
    } 
  };
  
  const termBtnEvent =()=>{
    if(termCheck === false) {
      setTermCheck(true)
    }else {
      setTermCheck(false)
    }
  };
  
  const pInfoBtnEvent =()=>{
    if(pInfoCheck === false) {
      setPInfoCheck(true)
    }else {
      setPInfoCheck(false)
    }
  };

  useEffect(()=>{
    if(termCheck===true && pInfoCheck===true){
      setAllCheck(true)
    } else {
      setAllCheck(false)
    }
  }, [termCheck,pInfoCheck])

  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center">
          <Col>
            <Form
              style={{ display: "flex", justifyContent: "center" }}
              onSubmit={onAllAgreement}
            >
              <div className="modalOverlay" />
              <Card className="modalCard">
                <Row className="w-100" style={{ margin: "0px 0px 15px 0px" }}>
                  <Col
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    약관 동의
                  </Col>
                  </Row>
                  {/* <Row className="w-100" style={{ margin: "0px 0px 22px 0px" }}>
                    <Col style={{ fontSize: "17px", fontWeight: "300" }}>
                      회원 가입을 위해서는 이용 약관 및 개인정보 이용 동의가 필요합니다.
                      아래 내용을 읽어보고, 동의해 주시기 바랍니다.
                    </Col>
                  </Row> */}
                  
                  <Row className="w-100" style={{ margin: "0px 0px 20px 0px" }}>
                    
                  </Row>
                  <Row className="w-100 m-0">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "6px",
                    }}
                    >
                    <input
                      type="checkbox"
                      style={{
                        width: "18px",
                        height: "19px",
                        marginRight: "10px",
                      }}
                      checked={termCheck} onChange={termBtnEvent}
                    />
                    <span>이용 약관 동의</span>
                  </div>
                  </Row>

                  <Row className="w-100 m-0">
                  <div
                  style={{
                    width: "400px",
                    height: "200px",
                    overflow: "auto", 
                    border: "1px solid black",
                    padding: "0 0.5em",
                    fontSize: "12px",
                  }}
                  >
                   <p>버전 날짜: 2022년 12월</p>
                   <p>
                   귀하는 최종 사용권 계약 및 AGMS 제품, AGMS 서비스 또는 소프트웨어 앱을 사용(액세스 포함)하거나 이 이용약관의 “동의”을 클릭하면 이 이용약관에 동의하게 됩니다. 
                  본 계약에 언급된 “귀하”, “귀하의” 또는 “사용자”는 앱을 이용하는 개인 및 조직 모두를 포함합니다.
                  </p>
                  <p>
                   UXN이 인터넷을 통해 AGMS 제품 또는 소프트웨어 앱의 사용자에게 제공하는, AGMS 제품 또는 소프트웨어 앱으로 생성한 데이터의 액세스, 수집, 저장, 처리, 분석 및/또는 전송을 제공하거나 허가하는 내용의 데이터 서비스(“데이터 서비스”). 당사는 당사 웹사이트 및 당사의 데이터 서비스를 “AGMS  서비스”로 지칭합니다.
                  이 문서는 귀하와 UXN Ltd.(본 계약에서 “UXN ”, “당사”, “당사의” 또는 “우리”라고 명시됨) 간에 법적 구속력이 있는 계약서입니다. 이 계약서는 다음 사항에 적용됩니다:
                  귀하의 승인된 모바일 장치에서 이 AGMS 앱(모든 업데이트, 업그레이드, 버그 수정) 설치 및 이용, AGMS 웹 사이트의 데이터 관리 시스템 이용(“AGMS  데이터 관리 시스템”) (이 AGMS 앱은 AGMS 데이터 관리 시스템에 데이터를 제공함), 및 이 AGMS 앱 이용을 설명하거나 이와 관련하여 UXN이 제공한 인쇄본 또는 전자 형식의 모든 매뉴얼, 지침, 설명서, 사양 또는 기타 자료(“문서”). 
                  </p>
                  <p>
                   이 AGMS 앱 및 AGMS 데이터 관리 시스템은 당뇨병의 진단, 검진, 혈당 수치 이상 알림을 목적으로 하지 않습니다.
                  사용자는 AGMS 데이터 관리 시스템이 포도당 및 관련 데이터 분석을 지원하는 정보 관리 서비스일 뿐이며 의료전문가의 조언을 대체하여 사용될 수 없다는 점을 인지해야 합니다.
                  개인은 당뇨 관리에 대한 의문이나 우려 사항을 비롯하여 의료적 상태에 대해 가질 수 있는 문의 사항이 있는 경우 항상 담당 의사나 자격 있는 기타 의료전문가와 상담해야 합니다.
                  </p>
                </div>
                </Row>

                <Row className="w-100 m-0">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "6px",
                    marginTop: "6px",
                  }}
                  >
                  <input
                    type="checkbox"
                    style={{
                      width: "18px",
                      height: "19px",
                      marginRight: "10px",
                    }}
                    checked={pInfoCheck} onChange={pInfoBtnEvent}
                  />
                  <span>개인정보 이용 동의</span>
                  </div>
                </Row>
                <Row className="w-100 m-0">
                <div
                style={{
                  width: "400px",
                  height: "200px",
                  overflow: "auto", 
                  border: "1px solid black",
                  padding: "0 0.5em",
                  marginBottom: "6px",
                  fontSize: "12px",
                }}
                >
                  <p>
                   UXN Ltd.과 계열사는 귀하의 개인정보를 소중하게 생각하며 모든 해당 개인정보 및 데이터 보호 법률을 준수하여정보 보호에 최선을 다하고 있습니다. 
                  이 개인정보보호 정책은 해당 개인정보 및 데이터 보호 법률에 따라 보호되는 개인 정보,즉 당사의 제품이나 서비스, 웹 사이트를 사용할 때 귀하에 대해 또는 
                  귀하를 식별하기 위해 수집 및 처리하는 개인 정보와이것의 수집 또는 처리 방법과 이유를 설명합니다. 
                  "개인 정보"에는 사이트 정보, 계정 정보, 주문 정보, 건강 데이터 및 기타 사용 정보,커뮤니케이션 정보, 제3자 정보, 제3자 사이트 정보, 파생 정보, 기타 식별 정보가 포함됩니다.
                  </p>
                  <p>
                   “UXN ”, “당사”라고 지칭하는 경우, 각각의 제품과 서비스(아래에 정의)의 약관에 따라 귀하에게 제품과 서비스를 제공하며 그에 따라이러한 제품과 서비스와 연계되어 수집되고 처리된 개인 정보에 대한 관리자 역할을 하는 UXN 기업 실체, 
                   또는 개인정보보호 정책에 따라합법적으로 공유된 개인 정보를 보유한 계열사를 의미합니다. 
                   이 개인정보 보호 정책은 또한 귀하의 권리, 권리의 행사 방법, 당사와의 연락방법을 설명하고 있습니다.
                   당사의 제품 및 서비스에는 웹 사이트(온라인 스토어 포함), 소프트웨어 애플리케이션, AGMS 제품이 포함됩니다(통틀어 “제품 및 서비스”).
                   당사는 귀하가 AGMS 서비스 사용 계정을 생성할 때 입력한 개인 정보를 사용합니다.
                   당사는 센서 사용이력, 포도당 관련 수치 데이터를 처리합니다. 
                   </p>
                   <p>
                   당사는 귀하가 고객 지원을 요청하고 시스템 문제 해결/진단 데이터를 공유하는 경우 개인 정보를 수집합니다
                   또한 당사 웹 사이트에 접근하기 위해 사용하는 장치, 앱, 인터넷 서비스, IP 주소, 브라우저에 대한 
                   정보와 당사 웹 사이트 방문 전후에 방문하는사이트 등의 온라인 탐색 행위, 열람하는 페이지, 열람 기간, 읽는 제품 설명, 제출된 양식, 시청한 비디오, 장바구니 콘텐츠,
                   사이트 페이지클릭수를 포함한 사이트에서의 행동, 그리고 비밀번호, 보안 응답, 귀하가 제공하는 사용자 환경 설정에 대한 정보가 제한없이 포함됩니다.
                   본 개인정보취급방침은 귀하의 AGMS 시스템 사용에 적용되고 아울러 건강 관련 정보를 비롯한 귀하의 개인 정보를 
                   UXN과 그 특정 제휴사가 클라우드에서 처리, 전송, 보관하는 과정에 적용됩니다. 본 개인정보 보호 고시는 UXN의 개인정보 및 데이터 보호관행을 설명하며, AGMS 데이터 관리 시스템에서 처리한 개인정보에만 적용됩니다.
                  </p>

                </div>
                </Row>

                <Row className="w-100 m-0">
                  <Col className="d-flex justify-content-between">
                  <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "6px",
                  }}
                  >
                  <input
                    type="checkbox"
                    style={{
                      width: "18px",
                      height: "19px",
                      marginRight: "10px",
                    }}
                    checked={allCheck} onChange={allBtnEvent}
                  />
                  <span>전체동의</span>
                  </div>
                  </Col>
                </Row>

                <Row className="w-100 row p-0 m-20">
                  <Col className="d-flex justify-content-center">
                    <Button
                      style={{
                        border: "none",
                        backgroundColor: "#A7A7A7",
                        borderRadius: "8px",
                        padding: "15px 40px",
                        // float: "right",
                        color: "#000",
                      }}
                      onClick={() => closeXModal()}
                    >
                      취소
                    </Button>

                    <Button
                      style={{
                        border: "none",
                        backgroundColor: "#3EA6FF",
                        borderRadius: "8px",
                        padding: "15px 40px",
                        color: "#fff",
                        fontWeight: "600",
                        // float: "right",
                        margin: "0px 0px 0px 5px"
                      }}
                      type="submit"
                    >
                      확인
                    </Button>

                  </Col>
                </Row>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(TermAgreement);
