import React from "react";
import { withRouter } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import BackImg from "../../assets/settings-265131_1920_2021-11-02/settings-265131_1920.png";
import "./profile.scss";
const MyDevice = () => {
  return (
    <React.Fragment>
      <img src={BackImg} alt="backimg" className="dashboardBackImg" />
      <div className="account-pages">
        <Row className="dashRow m-0">
          <Col md="7">
            <Card className="listAddCard">
              <div className="deviceInWrap">
                <div className="d-flex justify-content-center">
                  <span className="myDeviceName">장치 업로드</span>
                </div>
                <div className="birthDay d-flex justify-content-center">
                  혈당 장치를 업로드하여 귀하의 계정에 연결하십시오.
                </div>
              </div>
            </Card>
            <div className="deviceBtn">
              <button className="profileBtn326" type="submit">
                <span>추가</span>
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default withRouter(MyDevice);
