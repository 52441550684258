import "./profile.scss";

import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
//import Cards from "../../components/cards/Cards";
import "./main.scss";
import { requestGet, agmsClientRequestGet, agmsClientRequestPatch, agmsClientRequestPost } from "../../apis/request";

import BorderColorIcon from '@mui/icons-material/BorderColor';
import IconButton from '@mui/material/IconButton';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import secureLocalStorage from "react-secure-storage";

const Setting = () => {
  const history = useHistory();
  const [staffInfo , setStaffInfo] = useState({
    email: "",
    hospitalName: "",
    id: 0,
    name: "",
    phoneNumber: "",
    role: ""
  });


  //화면의 크기를 실시간으로 탐지하고 반영하기
  const [width, setWidth] = useState(window.innerWidth);
  const [openWithdraw, setOpenWithdraw] = React.useState(false);
  const [openEditPassword, setOpenEditPassword] = useState(false);
  
  const [password, setPassword] = useState('');
  
  const [isWithdrawal, setIsWithdrawal]= useState(false);
  const [widthStyle, setWidthStyle] = useState({
    marginTop:'100px',  
    marginLeft:'auto', 
    marginRight:'auto', 
    height:'auto', 
    border:'1px solid #151515', 
    width:'1000px',
    display:'flex', 
    flexDirection:'column', 
    alignItems:'center'
  });


  //API Call 메소드
  const callPasswordCheckApi = (input) => { //토큰 있어야 함
    let inputPassword = null;
    if(input === 'withdraw'){
      inputPassword = password;
    }
    else if(input === 'change_password'){
      inputPassword = current_password;
    }
    console.log('inputPassword');
    console.log(inputPassword);
    let passwordCheckResult = agmsClientRequestPatch(`/api/staff/password-check/${secureLocalStorage.getItem('email')}`, {
      password: inputPassword,
      //user_id: secureLocalStorage.getItem('email')
    });

    passwordCheckResult.then((data) => {
      //비밀번호 변경
      if(input === 'change_password'){
        if(data.message === 'True'){
          callApi_password_update();
        }
        else 
        {
          alert('기존 비밀번호가 일치하지 않습니다.');
        }
      }
      //회원 탈퇴
      else if(input === 'withdraw')
      {
        if(data.message === 'True'){
          setIsWithdrawal(true);
        }
        else
        {
          setIsWithdrawal(false);
        }
      } 
    });
  }

  const callWithDrawelApi = async () => {
    let withdrawerResult = null;
    if(secureLocalStorage.getItem('role') === 'ADMIN_STAFF'){
      withdrawerResult = await agmsClientRequestPost(`/api/staff/admin/delete/${secureLocalStorage.getItem('email')}`);
      alert('삭제되었습니다!');
      history.push('/login');
    }
    else{
      withdrawerResult =  agmsClientRequestPost(`/api/staff/delete/${secureLocalStorage.getItem('email')}`);
      alert('삭제되었습니다!');
      history.push('/login');
    }
    console.log(withdrawerResult);
  }


  //UI 이벤트 handle 메소드
  const handleWithdrawConfirmPassword = () => {
    callPasswordCheckApi('withdraw');
  }

  const handleClickOpen = (input) => {
    if(input === 'withdraw'){
      setPassword(null);
      setOpenWithdraw(true);
    }
    else{
      setPassword(null);
      set_current_password(null);
      set_new_password(null);
      set_new_password_confirm(null);
      setOpenEditPassword(true);
    }
  };

  const handleWithdrawClose = () => {
    setOpenWithdraw(false);
  }

  const onChangePasswordSignUp = (e) => {
    setPassword(e.target.value);
  }


  //비밀번호 변경
  const [current_password, set_current_password] = useState('');
  const [new_password, set_new_password] = useState('');
  const [new_password_confirm, set_new_password_confirm] = useState('');

  const handleEditPasswordClose = () => {
    setOpenEditPassword(false);
  };

  const onChangeCurrentPassword = (e) =>{
    set_current_password(e.target.value);
  }

  const onChangeNewPassword = (e) =>{
    set_new_password(e.target.value);
  }

  const onChangeNewPasswordConfirm = (e) =>{
    set_new_password_confirm(e.target.value);
  }
  
  const callApi_password_update = () => {
    let password_update_result = agmsClientRequestPatch(`/api/staff/password-update/${secureLocalStorage.getItem('email')}`, {
      password: new_password
    });
    password_update_result.then((data) => {
      if(data.message === 'password update succeed'){
        alert('성공적으로 변경 완료 되었습니다.')
        setOpenEditPassword(false);
      }
      else{
        alert('변경에 실패했습니다.')
        setOpenEditPassword(false);
      }
      console.log(data.message);
    });
    console.log(password_update_result);
  }

  const handleEditPasswordConfirm = (e) => {
    //비밀번호 check 일치하지 않았을 때
    if(new_password !== new_password_confirm){
      alert('새 비밀번호 확인이 일치하지 않습니다.');
    }else{
      callPasswordCheckApi('change_password');
    }
  }

  const callApi = async (input) => {
    try{
      let staffResult = await agmsClientRequestGet(`/api/staff/${input}`);
      setStaffInfo(staffResult);
    }
    catch(error){
      console.log('Dashboard callApi 에러 : ' + error);
    }
    
  };

  //useEffect들
  useEffect(() => {
    // const handleResize = () => setWidth(window.innerWidth);
    // window.addEventListener('resize', handleResize);
    // return () => window.removeEventListener('resize', handleResize);
    callApi(secureLocalStorage.getItem('email'));
  }, []);

  useEffect(()=>{
    if(isWithdrawal === true){
      callWithDrawelApi();
    }
  }, [isWithdrawal]);

  useEffect(()=>{
    if(width > 1000){
      setWidthStyle({
        marginTop:'100px',  
        marginLeft:'auto', 
        marginRight:'auto', 
        height:'auto', 
        border:'1px solid #151515', 
        width:'1000px',
        display:'flex', 
        flexDirection:'column', 
        alignItems:'center'
      });
    }else{
      setWidthStyle({
        marginTop:'100px',  
        marginLeft:'auto', 
        marginRight:'auto', 
        height:'auto', 
        border:'1px solid #151515', 
        width:'80%',
        display:'flex', 
        flexDirection:'column', 
        alignItems:'center'
      });
    }
  },[width]);

  
  useEffect(() => {
    //callApi();
  }, []);



  return (
    <div style={{height:'85vh'}}>
      <Card style={widthStyle}>
        <div style={{marginTop:'50px',marginBottom:'50px', display:'flex', flexDirection:'column', alignItems:'center'}}>
          <div style={{fontWeight:'bold', fontSize:'30px'}}>
            <span>버전 정보</span>
            
          </div>
          
          <div style={{height:'50px'}}></div>
          
          <div style={{
            display:'flex', 
            alignItems:'center',
            justifyContent:'center', width:'130%', flexDirection:'row'}}>
            <div style={{flex:'1'}}>
              <span style={{fontWeight:'bold',fontSize:'15px'}}>뷰어 : </span> 
              <span style={{fontSize:'15px'}}>ver 1.1.2</span>
            </div>

            <div>
              <span style={{fontWeight:'bold',fontSize:'15px'}}>서버 : </span>
              <span style={{fontSize:'15px'}}>ver 1.1.2</span>
            </div>
          </div>
        </div>
        
      </Card>

      {/* 회원탈퇴 */}
      <Dialog
        open={openWithdraw}
        fullWidth
        onClose={handleWithdrawClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"비밀번호 입력해주세요"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

            <TextField 
                      fullWidth 
                      type="password"
                      // label="비밀번호" 
                      placeholder='비밀번호'
                      value={password}
                      onChange={onChangePasswordSignUp}
                      />

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWithdrawClose}>취소</Button>
          <Button onClick={() => handleWithdrawConfirmPassword()} autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>

      {/* 비밀번호 변경 */}
      <Dialog
        open={openEditPassword}
        fullWidth
        onClose={handleEditPasswordClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"비밀번호 변경"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          
          <div style={{height:"15px"}}></div>
          <TextField 
              fullWidth 
              type="password"
              label="기존 비밀번호" 
              value={current_password}
              onChange={onChangeCurrentPassword}/>

          <div style={{height:"15px"}}></div>
          <div>
            영문자, 숫자, 특수문자를 포함한 8~35자 비밀번호를 입력해주세요.
          </div>
          <div>
            허용된 특수문자 :!@%$%^&*()+=._-
          </div>
          <div style={{height:"15px"}}></div>
          <TextField 
              fullWidth 
              type="password"
              label="새 비밀번호" 
              value={new_password}
              onChange={onChangeNewPassword}/>

          <div style={{height:"15px"}}></div>          
          <TextField 
              fullWidth 
              type="password"
              label="새 비밀번호 확인"
              value={new_password_confirm} 
              onChange={onChangeNewPasswordConfirm}/>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditPasswordClose}>취소</Button>
          <Button onClick={() => handleEditPasswordConfirm()} autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default withRouter(Setting);
